import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MenuModule } from '@app/primeng-overrides/menu';
import { TriStateCheckboxModule } from '@app/primeng-overrides/tristatecheckbox';
import { TreeNode } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';


import { GlobalModule } from '@global/global.module';

import { TranslateModule } from '@codeandweb/ngx-translate';
import { ControlValueAccessorBase } from '@helpers/control-value-accessor-base';

import {
	getFilteredNomenclature,
	makeNomenclatureTreeNodes,
	NomenclatureNodeData,
	Nomenclature,
	NomenclatureService,
	NomenclatureFilters,
	walkTree,
	NomenclatureNodeType
} from '@app/nomenclature/nomenclature.service';

import { expandTreeData } from '@helpers/utils';

@Component({
	selector: 'nomenclature-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NomenclatureSelectorComponent),
			multi: true
		}
	],
	templateUrl: `./nomenclature-selector.html`
})
export class NomenclatureSelectorComponent extends ControlValueAccessorBase<NomenclatureNodeData|null> implements OnInit {

	@Input() name: string;
	@Input('value') innerValue: NomenclatureNodeData|null;
	@Input() placeholder: string;
	@Input() showFilters: boolean = false;
	@Input() showFiltersOut: boolean = false;
	@Input() showSimpleFilter: boolean = false;
	@Input() treeSelect: boolean = true;
	@Input() selectableNodes: NomenclatureNodeType[] = [];
	@Output() onSelectionChange: EventEmitter<NomenclatureNodeData|null> = new EventEmitter<NomenclatureNodeData|null>();
	@Input() expanded: boolean = true;
	@Input() showInactive: boolean | null = false;
	@Input() showInactiveFilter: boolean = false;
	@Input() appendSelectToBody: boolean = false;
	@Input() nomenclature: Nomenclature;

	virtualScrollItemSize = 18;

	_defaultFilter: any = {
		fam: null,
		thm: null,
		rub: null,
	}
	@Input() filters: NomenclatureFilters = structuredClone(this._defaultFilter);

	unfilteredNomenclature: Nomenclature = {familles: [], rubriques: [], themes: []};
	hierarchy: TreeNode<NomenclatureNodeData>[];

	loading: boolean = false;

	simpleFilter: string = '';

	selected: TreeNode|null;

	constructor(
		private nomenclatureService: NomenclatureService
	) {
		super();
	}

	ngOnInit() {
		if (this.appendSelectToBody) {
			this.virtualScrollItemSize = 26; // Nécessaire sinon problème d'affichage
		}

		if (this.nomenclature) {
			this.setOptions(this.nomenclature);
		}
		else {
			this.load();
		}

	}

	load() {
		this.loading = true;
		this.nomenclatureService.getCacheNomenclature()
		.subscribe({
			next: (response: Nomenclature) => {
				this.setOptions(response);
			}
		})
		.add(() => { this.loading = false; });
	}

	setOptions(data: Nomenclature) {
		this.unfilteredNomenclature = data;
		this.updateTree(getFilteredNomenclature(this.unfilteredNomenclature, this.filters));
		this.toggleExpand(this.expanded);
		setTimeout(() => {
			// try to find and set the selected node
			if (this.value) {
				this.selected = this.findNodeFromData(this.value);
			}
		}, 10);
	}

	updateTree(nomenclature: Nomenclature) {
		this.hierarchy = structuredClone(makeNomenclatureTreeNodes(nomenclature, this.showInactive));

		if (this.selectableNodes.length > 0) {
			walkTree(this.hierarchy, (node) => {
				node.selectable = this.selectableNodes.includes(node.data!.typeNomenclature);
			});
		}
	}

	findNodeFromData(data: NomenclatureNodeData) {
		return this.getNodeWithKey(data.key, this.hierarchy);
	}

	getNodeWithKey(key: string, nodes: TreeNode<any>[]): TreeNode<any> | null {
		for (let node of nodes) {
			if (node.key === key) {
				return node;
			}

			if (node.children) {
				let matchedNode = this.getNodeWithKey(key, node.children);
				if (matchedNode) {
					return matchedNode;
				}
			}
		}
		return null;
	}

	resetFilter() {
		this.showInactive = false;
		this.filters = structuredClone(this._defaultFilter);
		this.filterChange();
	}

	filterChange() {
		this.updateTree(getFilteredNomenclature(this.unfilteredNomenclature, this.filters));
		this.toggleExpand(this.expanded);
	}

	toggleExpand(expand: boolean = !this.expanded) {
		this.hierarchy = expandTreeData(this.hierarchy, expand);
		this.expanded = expand;
	}

	selectionChange(node: TreeNode|null = this.selected) {
		this.value = node? node.data : null;
		this.onSelectionChange.emit(this.value);
	}


}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		InputTextModule,
		IconFieldModule,
		InputIconModule,
		MenuModule,
		ToolbarModule,
		TreeModule,
		TriStateCheckboxModule,
		TranslateModule,
		TreeSelectModule,
	],
	exports: [NomenclatureSelectorComponent],
	declarations: [NomenclatureSelectorComponent]
})
export class NomenclatureSelectorModule { }


