import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

import { map } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { Site } from './site.model';
import { UtilisateurSite } from '@app/utilisateur/utilisateur.model';

import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import { clone, convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject, uid } from '@helpers/utils';
import { Diagnostic } from '@app/diagnostic/diagnostic.model'


@Injectable({ providedIn: 'root' })
export class SiteService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	constructor(
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private http: HttpClient,
		private stateService: StateService,
	) {

	}

	ngOnDestroy(): void {
	}

	public prepareSiteFromServer(site: Site) {
		let tmp: Site = clone(site);
		convertDateFieldsToDate(tmp);
		return tmp;
	}

	public prepareSiteForServer(site: Site) {
		let tmp = structuredClone(site) as ExtensibleObject;
		convertDateFieldsToString(tmp);
		return tmp;
	}

	public prepareSitesFromServer(sites: Site[]) {
		for (let i = 0; i < sites.length ; i++) {
			sites[i] = this.prepareSiteFromServer(sites[i]);
		}
		return sites;
	}

	public getSites(abo_id: number, params: unknown = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`/abonnes/${abo_id}/sites`, tmpParams).pipe(
			map(({ sites, total }) => {
				let liste = this.prepareSitesFromServer(sites);
				return {sites: liste, total: total || 0 as number};
			})
		)
	}

	public getSite(abo_id: number, sit_id: number) {
		return this.http.get<any>(`/abonnes/${abo_id}/sites/${sit_id}`).pipe(
			map(response => this.prepareSiteFromServer(response))
		)
	}

	public createSite(abo_id: number, site: Site){
		let body = this.prepareSiteForServer(site);

		return this.http.post<any>(`/abonnes/${abo_id}/sites`, body);
	}

	public updateSite(abo_id: number, site: Site){
		let body = this.prepareSiteForServer(site);

		return this.http.put<any>(`/abonnes/${abo_id}/sites/${site.sit_id}`, body);
	}

	public deleteSite(abo_id: number, sit_id: number){
		return this.http.delete<any>(`/abonnes/${abo_id}/sites/${sit_id}`);
	}

	// Utilisateur

	public linkUserToSite(user_id: number, abo_id: number, sit_id: number, nac_id: number){
		return this.http.post<any>(`/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`, {
			nac_id: nac_id,
		});
	}

	public unlinkUserFromSite(user_id: number, abo_id: number, sit_id: number){
		return this.http.delete<any>(`/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`);
	}

	public editUtilisateurSite(user_id: number, abo_id: number, sit_id: number, utiSite: UtilisateurSite){
		return this.http.put<any>(`/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`, {
			nac_id: utiSite.nac_id,
		});
	}

	// Diagnostic

	public getDiagnostic(abo_id: number, sit_id: number) {
		return this.http.get<Diagnostic>(`/abonnes/${abo_id}/sites/${sit_id}/diagnostic`);
	}

	public updateDiagnostic(abo_id: number, sit_id: number, rub_ids: number[]) {
		return this.http.put(`/abonnes/${abo_id}/sites/${sit_id}/diagnostic`, rub_ids);
	}

	public duplicateDiagnostic(sit_id_source: number, abo_id_destination: number, sit_id_destination: number) {
		return this.http.put<{partial: boolean}>(`/abonnes/${abo_id_destination}/sites/${sit_id_destination}/diagnostic`, {sit_id_source: sit_id_source});
	}

	public exportDiagnostic(abo_id: number, sit_id: number) {
		let tmpParams = prepareQueryParamsForDownload({});
		return this.http.get(`/abonnes/${abo_id}/sites/${sit_id}/diagnostic/export`, tmpParams);
	}
}
