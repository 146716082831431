<p-panel class="no-header-panel">

	<div class="flex flex-column p-1">
		<form #famillesForm="ngForm">
			<div class="flex flex-column">
				<div>
					<button
						*ngIf="!editMode"
						type="button"
						pButton
						[label]="'families.add_family_button_label' | translate"
						icon="pi pi-link"
						(click)="openFamilleLinkDialog()"
					></button>
				</div>

				<div class="flex flex-row flex-wrap mt-4 gap-3">
					<p-card *ngFor="let famille of abonne.familles">

						<div class="text-lg flex flex-row align-items-center justify-content-between gap-4">
							<span>{{famille.fam_nom}}</span>

							<div class="flex flex-row align-items-center">
								<button
									type="button"
									pButton
									icon="pi pi-times-circle"
									class="p-button-rounded p-button-text p-button-danger"
									(click)="unlinkFamille(famille)"
								></button>
							</div>
						</div>
					</p-card>
				</div>
			</div>
		</form>
	</div>

</p-panel>
