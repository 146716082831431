import { CommonModule } from '@angular/common';
import { Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';


import { StateService, UIRouterModule } from '@uirouter/angular';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { FamilleSelectorModule } from '@app/famille/famille-selector';
import { Famille, Theme } from '@app/nomenclature/nomenclature.model';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { GlobalModule } from '@global/global.module';

import { uid } from '@helpers/utils';

@Component({
	selector: 'theme-dialog',
	templateUrl: `theme-dialog.component.html`
})
export class ThemeDialogComponent implements OnInit, OnDestroy {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	famille: Famille;
	theme: Theme;
	pristine: Theme;

	submitted = false;
	canEdit = false;
	isCreation = false;
	editMode = false;
	loading = false;

	constructor(
		private config: DynamicDialogConfig,
		private stateService: StateService,
		private nomenclatureService: NomenclatureService,
		private eventManager: EventManagerService,
		private dialogRef: DynamicDialogRef,
		private translationService: TranslateService,
	) {
		this.canEdit = config.data.canEdit;
		this.theme = config.data.theme;
		this.famille = config.data.famille;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});

		this.isCreation = this.theme == undefined;
		this.pristine = structuredClone(this.theme);

		if (this.isCreation) {
			this.editMode = true;
			this.theme = {thm_actif: true, fam_id: this.famille.fam_id} as Theme;
		}
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}


	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	submit(){
		this.submitted = true;

		if (this.isCreation) {
			this.nomenclatureService.createTheme(this.theme)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translationService.instant('themes.theme_created_success') });
					this.dialogRef.close({refresh: true});
				}
			});
		}
		else {
			this.nomenclatureService.updateTheme(this.pristine.fam_id, this.theme)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translationService.instant('themes.theme_updated_success') });
					this.dialogRef.close({refresh: true});
				}
			});
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		CalendarModule,
		DividerModule,
		CheckboxModule,
		LogoUploaderModule,
		InputTextareaModule,
		FamilleSelectorModule
	],
	exports: [ThemeDialogComponent],
	declarations: [ThemeDialogComponent]
})
export class ThemeDialogModule { }
