import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { GlobalModule } from '@app/_global/global.module';
import { Texte } from '@app/texte/texte.model';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { NomenclatureSelectorModule } from '@app/nomenclature/nomenclature-selector';

import { uid } from '@helpers/utils';
import { NomenclatureNodeData } from '@app/nomenclature/nomenclature.service';

@Component({
	selector: 'ajout-rubrique-dialog',
	template: `
		<form class="mb-2" #ajoutRubriqueDialog="ngForm" (ngSubmit)="submit()">
			<div class="p-fluid formgrid grid">

				<div class="field col-12">
					<label>
						{{'rubriques.rubrique_label' | translate}}
					</label>
					<nomenclature-selector
						name="rubrique"
						[(ngModel)]="nomenclatureNodeData"
						[selectableNodes]="['rubrique']"
						[placeholder]="'rubriques.rubrique_label' | translate"
						[appendSelectToBody]="true"
						required
					></nomenclature-selector>
				</div>

			</div>

			<!-- Submit -->
			<div class="text-center w-full mt-4">
				<button
					type="submit"
					pButton
					[label]="'common.add_button_label' | translate"
					[disabled]="!ajoutRubriqueDialog.form.valid">
				</button>
			</div>
		</form>
	`,
	providers: []
})
export class AjoutRubriqueDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	texte: Texte;
	nomenclatureNodeData: NomenclatureNodeData|null;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
	) {
		this.texte = config.data.texte;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	submit(){
		if (this.nomenclatureNodeData?.typeNomenclature == 'rubrique') {
			this.dialogRef.close(this.nomenclatureNodeData);
		}
		else {
			this.dialogRef.close();
		}
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		ButtonModule,
		InputTextareaModule,
		NomenclatureSelectorModule
	],
	exports: [AjoutRubriqueDialogComponent],
	declarations: [AjoutRubriqueDialogComponent]
})
export class AjoutRubriqueDialogModule { }

