<!-- Titre -->
<div class="page-title">
	{{ 'nomenclature.admin_page_title' | translate }}
</div>

<p-toolbar styleClass="pb-0">
	<ng-template pTemplate="left">

		<div class="grid form-grid fluid">
			<div class="field col-fixed">
				<label for="filter_fam">{{'families.family_label'|translate}}</label>
				<div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_fam"
						[(ngModel)]="filters.fam"
						placeholder="{{'common.search_input_placeholder'|translate}}"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.fam || !filters.fam" (click)="filters.fam = null"></button>
				</div>
			</div>

			<div class="field col-fixed">
				<label for="filter_thm">{{'themes.theme_label'|translate}}</label>
				<div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_thm"
						[(ngModel)]="filters.thm"
						placeholder="{{'common.search_input_placeholder'|translate}}"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.thm || !filters.thm" (click)="filters.thm = null"></button>
				</div>
			</div>

			<div class="field col-fixed">
				<label for="filter_rub">{{'rubriques.rubrique_label'|translate}}</label>
				<div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_rub"
						[(ngModel)]="filters.rub"
						placeholder="{{'common.search_input_placeholder'|translate}}"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.rub || !filters.rub" (click)="filters.rub = null"></button>
				</div>
			</div>

			<div class="field-checkbox col-fixed align-items-end">
				<p-triStateCheckbox
					name="showInactive"
					label="{{'common.inactive'|translate}}"
					[(ngModel)]="showInactive"
					(onChange)="filterChange()"
					checkboxFalseIcon=""
					[allowNull]="true"
					[trueValue]="false"
					[falseValue]="true"
					[highLightFalse]="false"
					checkboxTrueIcon="pi pi-check"
					checkboxNullIcon="pi pi-minus"
					[highLightNull]="true"
					class="mb-2"
				></p-triStateCheckbox>

				<!-- <button
					type="button"
					pButton
					icon="pi pi-refresh"
					class="p-button-rounded p-button-text p-button-sm p-button-danger"
					[loading]="loading"
					(click)="load()"
				></button> -->
			</div>

			<div class="field col-fixed flex align-items-end">
				<button
					type="button"
					pButton
					class="p-button-text p-button-sm"
					[disabled]="filters.fam == null && filters.thm == null && filters.rub == null && showInactive == false"
					label="{{'common.reset_filters'|translate}}"
					(click)="resetFilter()"
				></button>
			</div>
		</div>


	</ng-template>
</p-toolbar>

<p-tree
	#tree
	[value]="hierarchy"
	class="w-full md:w-30rem"
	[virtualScroll]="true"
	[virtualScrollItemSize]="28"
	scrollHeight="flex"
	class="block flex-grow-1"
	styleClass="h-full"
	[indentation]="1"
	[loading]="loading"
>
	<ng-template pTemplate="header">
		<div class="flex flex-row">
			<!-- Création de famille -->
			<button
				*ngIf="canEdit"
				type="button"
				pButton
				icon="pi pi-plus"
				class="p-button-rounded p-button-text p-button-sm pl-1"
				(click)="showChildItemCreationDialog()"
				[label]="'nomenclature.create_famille' | translate"
			></button>

			<!-- Réduction de l'arbre -->
			<button
				type="button"
				pButton
				icon="p-button-icon-left pi {{ expanded? 'pi-arrow-down-left-and-arrow-up-right-to-center' : 'pi-arrow-up-right-and-arrow-down-left-from-center' }}"
				class="p-button-rounded p-button-text p-button-sm"
				(click)="toggleExpand()"
			>
				<span [translate]="expanded? 'common.collapse' : 'common.expand' "></span>
			</button>
		</div>
	</ng-template>

	<ng-template let-node pTemplate="default">
		<div class="flex align-items-center nomenclature-item" title="{{node.label}}">
			<!-- Label du noeud, peut être barré si le noeud est inactif -->
			<div
				class="white-space-nowrap text-overflow-ellipsis overflow-hidden flex-grow-1"
				[ngClass]="{'line-through': !node.data.actif}"
			>
				<i
					class="pi pi-lock text-primary"
					*ngIf="node.typeNomenclature == 'rubrique' && node.data.abo_id_privatisation"
					title="{{'common.private'|translate}}"
				></i>
				{{node.label}}
			</div>
			<div class="white-space-nowrap flex-shrink-0 nomenclature-buttons">
				<!-- Détails -->
				<button
					type="button"
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text p-button-sm"
					(click)="showItemDialog(node.data)"
				></button>

				<!-- Création d'un élément enfant -->
				<button
					*ngIf="canEdit && node.typeNomenclature != 'rubrique'"
					type="button"
					pButton
					icon="pi pi-plus"
					class="p-button-rounded p-button-text p-button-sm"
					(click)="showChildItemCreationDialog(node.data)"
					title="{{
						node.typeNomenclature == 'famille' ?
						('nomenclature.create_theme'|translate)
						: ('nomenclature.create_rubrique'|translate)
					}}"
				></button>

				<!-- Suppression -->
				<button
					*ngIf="canEdit"
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-sm p-button-danger"
					[loading]="loadingItem == node.key"
					[disabled]="loadingItem != null"
					title="{{'common.delete_button_label'|translate}}"
					(click)="deleteItem(node.data)"
				></button>
			</div>
		</div>
	</ng-template>

</p-tree>
