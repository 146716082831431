import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { ButtonModule } from 'primeng/button';

import { Abonne } from '@app/abonne/abonne.model';
import { GlobalModule } from '@global/global.module';
import { AbonneService } from '@app/abonne/abonne.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { AbonneLogoDisplayModule } from '@app/abonne/abonne-logo-display/abonne-logo-display';

@Component({
	selector: 'selection-abonne-courant',
	template: `
		<div class="flex flex-column align-items-center w-full">
			<form>
				<div class="field w-20rem">
					<label class="text-2xl mt-3 mb-3"> {{ 'abonnes.current_abonne_selector' | translate }} </label>

					<p-dropdown
						*ngIf="choices"
						name="abo_id"
						required
						[options]="choices"
						[(ngModel)]="abo_id"
						(ngModelChange)="onSelectionChange()"
						[filter]="choices.length > 5"
						filterBy="abo_nom_abonne"
						placeholder="{{'abonnes.select'|translate}}"
						optionValue="abo_id"
						optionLabel="abo_nom_abonne"
						appendTo="body"
						styleClass="w-10 h-3rem"
						inputStyleClass="flex flex-row align-items-center"
					>
						<ng-template let-selectedItem pTemplate="selectedItem">
							<div class="flex flex-row align-items-center">
								<div class="mr-2">
									<logo-display [abonne]="selectedItem" width="3rem"></logo-display>
								</div>
								{{selectedItem.abo_nom_abonne}}
							</div>
						</ng-template>

						<ng-template let-item pTemplate="item">
							<div class="flex flex-row align-items-center">
								<div class="mr-2">
									<logo-display [abonne]="item" placeholderText="-" width="3rem"></logo-display>
								</div>
								{{item.abo_nom_abonne}}
							</div>
						</ng-template>


					</p-dropdown>
				</div>
			</form>
		<div>
	`
})
export class SelectionAbonneCourantComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() choices: Abonne[];

	abo_id: number;

	constructor(
		private stateService: StateService,
		private abonneService: AbonneService,
	) {
	}

	ngOnInit(): void {
		if (this.me.uti_administrateur) {
			// L'administrateur a accès à tous les abonnés.
			this.abonneService.getAbonnes({})
			.subscribe(({ abonnes }) => {
				this.choices = abonnes;
			});
		}
		else {
			this.choices = [...this.me.abonnes];
		}
	}

	onSelectionChange(){
		if (this.abo_id == undefined) {
			return;
		}
		this.abonneService.currentAbonneValue = null;
 		this.stateService.go('abonne-courant', {curr_abo_id: this.abo_id});
	}

}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		GlobalModule,
		DropdownModule,
		FormsModule,
		ButtonModule,
		AbonneLogoDisplayModule,
		AbonneLogoDisplayModule
	],
	exports: [SelectionAbonneCourantComponent],
	declarations: [SelectionAbonneCourantComponent]
})
export class SelectionAbonneCourantModule { }
