import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, OnDestroy, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { Subscription } from 'rxjs';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { NomenclatureService } from '@app/nomenclature/nomenclature.service';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';
import { Theme } from '@app/nomenclature/nomenclature.model';
import { stringSort } from '@helpers/utils';

@Component({
	selector: 'theme-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ThemeSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[filter]="options.length > 5"
			filterBy="thm_nom"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			[virtualScroll]="true"
			[virtualScrollItemSize]="virtualScrollItemSize"
			optionValue="thm_id"
			optionLabel="thm_nom"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class ThemeSelectorComponent extends ControlValueAccessorBase<number> implements OnInit, OnDestroy, OnChanges  {

	@Input() name: string;
	@Input('value') innerValue: number;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;
	@Input() fam_id: number;

	_placeholder: string = 'Sélectionnez';

	options: {thm_id: number, thm_nom: string}[] = [];

	subscriptionLangChange?: Subscription;

	// TODO on devrait centraliser ça
	virtualScrollItemSize: number = 18;

	constructor(private nomenclatureService: NomenclatureService, private translateService: TranslateService) {
		super();

		if (this.placeholder == undefined) {
			this.subscriptionLangChange = this.translateService.onLangChange.subscribe(() => {
				this._placeholder = translateService.instant('common.selector_placeholder');
			});
		}
	}

	ngOnInit() {
		if (this.placeholder != undefined) {
			this._placeholder = this.placeholder;
		}
		this.refresh();
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange?.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.fam_id != undefined && !changes.fam_id.firstChange) {
			this.refresh();
		}
	}

	refresh() {
		this.nomenclatureService.getCacheNomenclature()
		.subscribe(({ themes }) => {
			let tmp = themes.filter((theme: Theme) => theme.thm_actif);
			stringSort(tmp, 'thm_nom');

			if (this.fam_id != undefined) {
				tmp = tmp.filter((theme: Theme) => theme.fam_id == this.fam_id);
			}

			this.options = [...tmp];
		});
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule
	],
	exports: [ThemeSelectorComponent],
	declarations: [ThemeSelectorComponent]
})
export class ThemeSelectorModule { }
