export const gestionnaireAbonneMenuItems: any[] = [
	{
		labelKey: 'menu.users',
		icon: 'pi pi-users',
		state: 'abonne-courant.utilisateurs'
	},

	{
		labelKey: 'menu.sites',
		icon: 'pi pi-map-marker',
		state: 'abonne-courant.sites',
	},

	{
		labelKey: 'menu.abonnement',
		icon: 'pi pi-info-circle',
		state: 'abonne-courant.abonnement',
	}
];
