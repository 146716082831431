import { CommonModule } from '@angular/common';
import { Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';

import { EventManagerService } from '@app/_global/event-manager.service';
import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { FamilleSelectorModule } from '@app/famille/famille-selector';
import { Rubrique, Theme } from '@app/nomenclature/nomenclature.model';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { ThemeSelectorModule } from '@app/theme/theme-selector';
import { GlobalModule } from '@global/global.module';

import { ExtensibleObject, uid } from '@helpers/utils';

@Component({
	selector: 'rubrique-dialog',
	templateUrl: `rubrique-dialog.component.html`
})
export class RubriqueDialogComponent implements OnInit, OnDestroy {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	theme: Theme;
	rubrique: Rubrique;
	pristine: Rubrique;

	submitted = false;
	canEdit = false;
	isCreation = false;
	editMode = false;
	loading = false;

	constructor(
		private config: DynamicDialogConfig,
		private stateService: StateService,
		private nomenclatureService: NomenclatureService,
		private eventManager: EventManagerService,
		private dialogRef: DynamicDialogRef,
		private translationService: TranslateService,
	) {
		this.canEdit = config.data.canEdit;
		this.theme = config.data.theme;
		this.rubrique = structuredClone(config.data.rubrique);
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});

		this.isCreation = this.rubrique == undefined;

		if (this.isCreation) {
			this.editMode = true;
			this.rubrique = {
				rub_actif: true,
				fam_id: this.theme.fam_id,
				thm_id: this.theme.thm_id,
				abo_id_privatisation: null
			} as Rubrique;
		}

		this.pristine = structuredClone(this.rubrique);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}


	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	onFamilleChange() {
		if (!this.isCreation) {
			(this.rubrique as ExtensibleObject).thm_id = undefined;
		}
	}

	submit(){
		this.submitted = true;

		if (this.isCreation) {
			this.nomenclatureService.createRubrique(this.pristine.fam_id, this.rubrique)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translationService.instant('rubriques.rubrique_created_success') });
					this.dialogRef.close({refresh: true});
				}
			});
		}
		else {
			this.nomenclatureService.updateRubrique(this.pristine.fam_id, this.pristine.thm_id, this.rubrique)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translationService.instant('rubriques.rubrique_updated_success') });
					this.dialogRef.close({refresh: true});
				}
			});
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		CalendarModule,
		DividerModule,
		CheckboxModule,
		LogoUploaderModule,
		InputTextareaModule,
		FamilleSelectorModule,
		ThemeSelectorModule,
		AbonneSelectorModule
	],
	exports: [RubriqueDialogComponent],
	declarations: [RubriqueDialogComponent]
})
export class RubriqueDialogModule { }
