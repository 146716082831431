import { CommonModule } from "@angular/common";
import {
	Component,
	forwardRef,
	Input,
	NgModule,
	OnInit,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";

import { EditorModule } from "primeng/editor";
import { TranslateModule } from "@codeandweb/ngx-translate";

import {
	quillDisableImageSupport,
	quillModuledisablePTagMadness,
	quillRegisterFontSizes,
	quillUseDivInsteadOfP
} from "@app/_helpers/quill-customization";
import { ControlValueAccessorBase } from "@app/_helpers/control-value-accessor-base";
import { ParametreService } from "@app/parametre/parametre.service";

@Component({
	selector: 'html-editor',
	templateUrl: './html-editor.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => HTMLEditorComponent),
			multi: true
		}
	],
})
export class HTMLEditorComponent extends ControlValueAccessorBase<string | null> implements OnInit {

	@Input('value') innerValue: string | null;
	@Input() required: boolean;
	@Input() disabled: boolean;
	@Input() readonly: boolean;
	@Input() style = {}

	@Input() typeChampFusion?: string
	@Input() fontSizes?: unknown[];
	@Input() headerSizes?: unknown[];

	quillModules: any = quillModuledisablePTagMadness;

	constructor(private parametreService: ParametreService){
		super();
		quillUseDivInsteadOfP();
	}

	ngOnInit(): void {
		if (this.fontSizes != undefined) {
			quillRegisterFontSizes(this.fontSizes);
		}
	}

	onQuillInit(event: any) {
		quillDisableImageSupport(event.editor);
	}

}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		EditorModule,
		TranslateModule,
	],
	exports: [HTMLEditorComponent],
	declarations: [HTMLEditorComponent]
})
export class HTMLEditorModule {}
