import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';


import { FileUploadModule } from '@app/primeng-overrides/fileupload';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';

import { GlobalModule } from '@app/_global/global.module';
import { Abonne } from '@app/abonne/abonne.model';

@Component({
	selector: 'logo-display',
	template: `
		<img
			[src]="url"
			[ngStyle]="{width: width, height: height}"
			*ngIf="abonne.abo_fichier_logo || !placeholderText"
			alt="logo {{abonne.abo_nom_abonne}}"
			title="{{abonne.abo_nom_abonne}}"
		>
		<div *ngIf="!abonne.abo_fichier_logo && placeholderText"
			[ngStyle]="{width: width, height: height}"
			class="flex flex-column align-items-center"
			title="{{abonne.abo_nom_abonne}}"
		>
			{{placeholderText}}
		</div>
	`
})
export class AbonneLogoDisplayComponent implements OnChanges {

	@Input() abonne: Abonne;
	@Input() width: string = '100%';
	@Input() height: string = '100%';
	@Input() placeholderText: string;

	url: string = '/assets/images/logo_small_bw.png';

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.abonne.abo_fichier_logo) {
			this.url = `/logo/${this.abonne.abo_id}/${this.abonne.abo_fichier_logo}`;
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		ButtonModule,
		FileUploadModule,
		PanelModule,
		TooltipModule,
		GlobalModule
	],
	exports: [AbonneLogoDisplayComponent],
	declarations: [AbonneLogoDisplayComponent]
})
export class AbonneLogoDisplayModule { }

