import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { Site } from '@app/site/site.model';
import { SiteService } from '@app/site/site.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';

@Component({
	selector: 'site',
	templateUrl: `site.component.html`
})
export class SiteComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() site: Site;
	@Input() abonne_courant: Abonne;

	initialSite: Site;

	tabs: any[];
	langChangeSubscription: Subscription;

	constructor(
		private stateService: StateService,
		private siteService: SiteService,
		private eventManager: EventManagerService,
		private translationService: TranslateService,
		private abonneService: AbonneService,
	) {

		this.setTabs();

		this.langChangeSubscription = translationService.onLangChange.subscribe(() => {
			this.setTabs();
		});
	}


	ngOnInit(): void {
		this.initialSite = structuredClone(this.site);
	}

	setTabs() {
		this.tabs = [
			{
				label: this.translationService.instant('sites.infos_tab_name'),
				state: '.informations',
			},

			{
				label: this.translationService.instant('sites.diagnostic_tab_name'),
				state: '.diagnostic',
			},
		];
	}


}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		CalendarModule,
		DividerModule,
		CheckboxModule,
		LogoUploaderModule,
		GzTabMenuModule,
	],
	exports: [SiteComponent],
	declarations: [SiteComponent]
})
export class SiteModule { }
