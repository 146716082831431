export const superAdminMenuItems: any[] = [
	{
		labelKey: 'menu.abonnes',
		icon: 'pi pi-building',
		state: 'admin.abonnes',
	},

	{
		labelKey: 'menu.users',
		icon: 'pi pi-users',
		state: 'admin.utilisateurs',
	},

	{
		labelKey: 'menu.nomenclature',
		icon: 'pi pi-sitemap',
		iconClass: '-rotate-90',
		// icon: 'pi pi-align-right',
		// iconClass: 'rotate-180',
		state: 'admin.nomenclature',
	},

	{
		labelKey: 'menu.texts',
		icon: 'pi pi-book',
		state: 'admin.textes',
	},
];
