import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { NiveauAccessSelectorModule } from '@app/abonne/niveau-acces-selector';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';

import { AbonneLogoDisplayModule } from '@app/abonne/abonne-logo-display/abonne-logo-display';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { AbonneModule } from '@app/abonne/abonne.component';
import { Abonne } from '@app/abonne/abonne.model';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { CiviliteSelectorModule } from '@app/utilisateur/civilite-selector';
import { Utilisateur, UtilisateurAbonne } from '@app/utilisateur/utilisateur.model';
import { TranslateService } from '@codeandweb/ngx-translate';
import { GlobalModule } from '@global/global.module';

import { AbonneService } from '@app/abonne/abonne.service';

@Component({
	selector: 'utilisateur',
	templateUrl: `utilisateur.component.html`
})
export class UtilisateurComponent implements OnInit, OnDestroy {

	@Input() me: Utilisateur;
	@Input() utilisateur: Utilisateur;
	@Input() isAdminView = false;

	initialUtilisateur: Utilisateur;

	tabs: any[];
	subscriptionLangChange: Subscription;
	currentAbonne?: Abonne;

	constructor(
		private translationService: TranslateService,
		private abonneService: AbonneService,
	) {
		this.currentAbonne = this.abonneService.currentAbonneValue!;
	}

	ngOnInit(): void {
		this.initialUtilisateur = structuredClone(this.utilisateur);

		this.updateTabs();

		this.subscriptionLangChange = this.translationService.onLangChange.subscribe(() => {
			this.updateTabs();
		});
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange.unsubscribe();
	}

	updateTabs(){
		let isSelfEditing = this.me.uti_id == this.utilisateur.uti_id;

		this.tabs = [
			{
				label: this.translationService.instant('users.general_tab_name'),
				state: '.general',
			},
		];

		if (isSelfEditing) {
			return;
		}

		if (this.isAdminView) {
			this.tabs.push({
				label: this.translationService.instant('users.linked_abonnes_tab_name'),
				state: '.abonnes',
			});
		}

		if (this.currentAbonne == undefined) {
			return;
		}

		let amIGestionnaireAbo =
			this.currentAbonne.utilisateurs_abonnes.find(utiAbo => utiAbo.utb_gestion_abonnement && utiAbo.uti_id == this.me.uti_id);

		let isUtilisateurLinkedToCurrentAbo = this.currentAbonne.utilisateurs_abonnes.find(utiAbo => utiAbo.uti_id == this.utilisateur.uti_id);

		if (isUtilisateurLinkedToCurrentAbo && (this.me.uti_administrateur || amIGestionnaireAbo)) {
			this.tabs.push({
				label: this.translationService.instant('users.access_tab_name'),
				state: '.acces',
			});
		}
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		DropdownModule,
		AbonneSelectorModule,
		AbonneModule,
		CiviliteSelectorModule,
		ToggleButtonModule,
		DividerModule,
		NiveauAccessSelectorModule,
		AbonneLogoDisplayModule,
		GzTabMenuModule
	],
	exports: [UtilisateurComponent],
	declarations: [UtilisateurComponent]
})
export class UtilisateurModule { }
