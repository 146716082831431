import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { Site } from '@app/site/site.model';
import { SiteService } from '@app/site/site.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';

@Component({
	templateUrl: `site-infos-tab.component.html`
})
export class SiteInfosTabComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() site: Site;
	@Input() abonne_courant: Abonne;

	initialSite: Site;

	submitted = false;
	canEditSite = false;
	isCreation = false;
	editMode = false;
	loading = false;

	constructor(
		private stateService: StateService,
		private siteService: SiteService,
		private eventManager: EventManagerService,
		private translationService: TranslateService,
		private abonneService: AbonneService,
	) {
	}


	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	ngOnInit(): void {
		this.isCreation = this.site == undefined;
		this.canEditSite = this.me.uti_administrateur;
		this.initialSite = structuredClone(this.site);

		if (this.isCreation) {
			this.editMode = true;
			this.site = {sit_actif: true} as Site;
		}
	}

	submit(){
		this.submitted = true;

		if (this.isCreation) {
			this.siteService.createSite(this.abonne_courant.abo_id, this.site)
			.subscribe({
				next: ({ sit_id }) => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translationService.instant('sites.site_created_success') });

					this.abonneService.refreshCurrentAbonneData()
					.subscribe(() => {
						this.stateService.go('^.single', {...this.stateService.params, sit_id: sit_id});
					});
				}
			})
		}
		else {
			this.siteService.updateSite(this.abonne_courant.abo_id, this.site)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translationService.instant('sites.site_updated_success') });
					this.abonneService.refreshCurrentAbonneData()
					.subscribe(() => {
						this.stateService.go('.', undefined, {reload: '^'});
					})
				}
			})
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		CalendarModule,
		DividerModule,
		CheckboxModule,
		LogoUploaderModule,
		GzTabMenuModule,
	],
	exports: [SiteInfosTabComponent],
	declarations: [SiteInfosTabComponent]
})
export class SiteInfosTabModule { }
