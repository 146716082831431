import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { AbonneService } from '@app/abonne/abonne.service';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { GlobalModule } from '@app/_global/global.module';
import { NiveauAccessSelectorModule } from '@app/abonne/niveau-acces-selector';
import { uid } from '@helpers/utils';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';

@Component({
	selector: 'abonne-lien-dialog',
	template: `
		<form class="mb-2" #lienAbonneForm="ngForm" (ngSubmit)="submit()">
			<div class="p-fluid formgrid grid">

				<div class="field col-12 sm:col-6">
					<label>
						{{'abonnes.abonne_label' | translate}}
					</label>
					<abonne-selector
						class="field col-fixed"
						name="abo_id"
						[(ngModel)]="abo_id"
						[placeholder]="'abonnes.abonne_label' | translate"
						[unavailableAbonnes]="unavailableAbonnes"
						required
					></abonne-selector>
				</div>

				<div class="field col-12 sm:col-6">
					<label>
						{{ 'abonnes.access_level_label' | translate }}
					</label>

					<niveau-access-selector
						name="nac_id"
						[(ngModel)]="nac_id"
						[placeholder]="'abonnes.access_level_label' | translate"
					>
					</niveau-access-selector>
				</div>

				<div class="field col-fixed flex align-items-center">
					<p-checkbox
						name="utb_gestion_abonnement"
						inputId="utb_gestion_abonnement"
						[(ngModel)]="utb_gestion_abonnement"
						[binary]="true"
						[label]="'abonnes.subscription_management_right_label' | translate"
					></p-checkbox>
				</div>

			</div>

			<!-- Submit -->
			<div class="text-center w-full">
				<button
					type="submit"
					pButton
					[label]="'abonnes.link_submit_button' | translate"
					[disabled]="!lienAbonneForm.form.valid"
					[loading]="loading"
				>
				</button>
			</div>
		</form>
	`,
	providers: []
})
export class AbonneLienDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	abo_id?: number;
	nac_id: number = 1
	utb_gestion_abonnement = false;
	utilisateur: Utilisateur;
	loading: boolean = false;

	unavailableAbonnes: number[];

	constructor(
		private abonneService: AbonneService,
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
	) {
		this.utilisateur = config.data.utilisateur;
		this.unavailableAbonnes = config.data.unavailableAbonnes;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {});
	}

	submit(){
		if (this.abo_id != undefined) {
			this.loading = true;
			this.abonneService.linkUserToAbonne(
				this.utilisateur.uti_id, this.abo_id, {
				nac_id: this.nac_id,
				utb_gestion_abonnement: this.utb_gestion_abonnement
			})
			.subscribe({
				complete: () => {
					this.dialogRef.close({refresh: true});
				}
			})
			.add(() => {this.loading = false;});
		}
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		ButtonModule,
		InputTextareaModule,
		AbonneSelectorModule,
		NiveauAccessSelectorModule
	],
	exports: [AbonneLienDialogComponent],
	declarations: [AbonneLienDialogComponent]
})
export class AbonneLienModule { }

