<p-editor
	#editor
	id="editor"
	[(ngModel)]="value"
	[modules]="quillModules"
	[style]="style"
	bounds="p-editor"
	[readonly]="readonly"
	(onInit)="onQuillInit($event)"
>
	<ng-template pTemplate="header">
		<div [ngClass]="{'hidden': readonly}">
			<span class="ql-formats">
				<ng-container *ngIf="fontSizes?.length > 0">
					<select class="ql-size" *ngIf="fontSizes">
						<ng-container *ngFor="let option of fontSizes">
							<option value="{{option.value}}" [selected]="option.default">{{option.label}}</option>
						</ng-container>
					</select>
				</ng-container>


				<ng-container *ngIf="headerSizes?.length > 0">
					<select class="ql-header">
						<ng-container *ngFor="let option of headerSizes">
							<option value="{{option.value}}" [selected]="option.default">{{option.label}}</option>
						</ng-container>
					</select>
				</ng-container>
			</span>

			<span class="ql-formats">
				<button type="button" class="ql-bold" title="{{'editor.bold'|translate}}"></button>
				<button type="button" class="ql-italic" title="{{'editor.italic'|translate}}"></button>
				<button type="button" class="ql-underline" title="{{'editor.underline'|translate}}"></button>
				<button type="button" class="ql-strike" title="{{'editor.strike'|translate}}"></button>
			</span>
			<span class="ql-formats">
				<select class="ql-color" title="{{'editor.text_color'|translate}}"></select>
				<select class="ql-background" title="{{'editor.background_color'|translate}}"></select>
			</span>
			<span class="ql-formats" title="{{'editor.list'|translate}}">
				<button type="button" class="ql-list" title="{{'editor.ordered_list'|translate}}" value="ordered"></button>
				<button type="button" class="ql-list" title="{{'editor.unordered_list'|translate}}" value="bullet"></button>
				<select class="ql-align" title="{{'editor.align'|translate}}">
					<option selected></option>
					<option value="center"></option>
					<option value="right"></option>
					<option value="justify"></option>
				</select>
			</span>
			<span class="ql-formats">
				<button type="button" class="ql-script" title="{{'editor.sub'|translate}}" value="sub"></button>
				<button type="button" class="ql-script" title="{{'editor.super'|translate}}" value="super"></button>
			</span>
			<span class="ql-formats">
				<button type="button" class="ql-link" title="{{'editor.insert_link'|translate}}"></button>
				<champ-fusion-quill-toolbar *ngIf="typeChampFusion" [editorInstance]="editor" [typeChamp]="typeChampFusion"/>
			</span>
		</div>
	</ng-template>
</p-editor>
