import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from 'primeng/inputtext';

import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';

@Component({
	selector: 'civilite-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CiviliteSelectorComponent),
			multi: true
		}
	],
	template: `
		<input
			type="text"
			pInputText
			[name]="name"
			[ngModel]="value"
			readonly
			*ngIf="readonly"
		>
		<p-dropdown
			[name]="name"
			#civilite
			[(ngModel)]="value"
			[editable]="true"
			[disabled]="disabled"
			[options]="options"
			[required]="required"
			placeholder="Sélectionnez ou saisissez une valeur"
			*ngIf="!readonly"
		></p-dropdown>
	`
})
export class CiviliteSelectorComponent extends ControlValueAccessorBase<string> implements OnInit  {

	@Input() name: string;
	@Input('value') innerValue: string;
	@Input() readonly: boolean;
	@Input() disabled: boolean;
	@Input() required: boolean;

	options: string[] = [];

	constructor(private utilisateurService: UtilisateurService) {
		super();
	}

	ngOnInit() {
		this.options = this.utilisateurService.getCivilites('long');
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
		InputTextModule,
	],
	exports: [CiviliteSelectorComponent],
	declarations: [CiviliteSelectorComponent]
})
export class CiviliteSelectorModule { }
