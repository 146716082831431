import { Observable, Observer, Subject } from 'rxjs';
import { TreeNode } from 'primeng/api';

export const decOctect: string = '(?:0{0,2}\\d|0?[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])'; // dec-octet = DIGIT / %x31-39 DIGIT / "1" 2DIGIT / "2" %x30-34 DIGIT / "25" %x30-35 ; 0-9 / 10-99 / 100-199 / 200-249 / 250-255
// export const ipv4address: string = '(?:' + decOctect + '\\.){3}' + decOctect; // IPv4address = dec-octet "." dec-octet "." dec-octet "." dec-octet
export const ipv4address: string = `(?:${decOctect}\\.){3}${decOctect}`; // IPv4address = dec-octet "." dec-octet "." dec-octet "." dec-octet
export const ipv4Cidr: string = '(?:\\d|[1-2]\\d|3[0-2])'; // IPv4 cidr = DIGIT / %x31-32 DIGIT / "3" %x30-32  ; 0-9 / 10-29 / 30-32
export const ipv4addressWithOptionalCidr: string = `${ipv4address}(/${ipv4Cidr})?`;

export const isNumeric = function (value: any): boolean {
	value = typeof(value) === 'string' ? value.replace(',', '.') : value;
	return !isNaN(parseFloat(value)) && isFinite(value) && Object.prototype.toString.call(value).toLowerCase() !== '[object array]';
};

export const isNumber = function (value: any): boolean {
	return typeof value === 'number' && isFinite(value);
}

export const isDateString = function(value: any, strictlyString: boolean = false): boolean {
	let parsed = Date.parse(value);
	return isNaN(value) && !isNaN(parsed) && (!strictlyString || strictlyString && typeof value == 'string');
};

export const isDate = function(value: any): boolean {
	return value instanceof Date || isDateString(value);
}

export const isObject = function(value: any): boolean {
	return value !== null && typeof value === 'object' && Array.isArray(value) === false;
}

export const isDefined = function(value: any): boolean {
	return typeof value != 'undefined';
}

export class ExtensibleObject {
	[key: string]: any;
}

export const allKeys = function(obj: any) {
	const keys: string[] = [];
	for (const prop in obj) keys.push(prop);
	return keys;
}

export const assignExisting = function(...args: any[]) {
	let destination: any = args.shift();
	let result = clone(destination);
	if (args.length > 1) {
		args.forEach((source: any) => {
			result = assignExisting(result, source);
		});
	}
	else {
		let source: any = args.pop();
		const sourceKeys = allKeys(source);
		for (const key in result) {
			if (sourceKeys.includes(key)) { // If in both destination and source
				result[key] = source[key]; // then assign property
			}
		}
	}
	return result;
}

export const networkAvailable = function(): boolean {
	// if(window.cordova){
	// 	return $cordovaNetwork.isOnline();
	// }
	// else{// otherwise we're on a desktop web browser
		return window.navigator.onLine;
	// }
};

export const isEmpty = function(obj: any): boolean {
	if (typeof obj == 'undefined' || obj === null) return true;
	for(let i in obj){
		return false;
	}
	return true;
};

export const simpleComparison = function(objA: Object, objB: Object): boolean {
	return JSON.stringify(objA) === JSON.stringify(objB);
};

export const addOrRemoveTrailingOrLeadingChars = function(str: string, chars: string = '/', remove: boolean = false, leading: boolean = false): string {
	let candidate: string;
	let result: string = str;
	let charsLength: number = chars.length;
	if (leading) {
		candidate = str.slice(0, charsLength);
		if (remove) {
			if (candidate == chars) result = str.slice(charsLength);
		}
		else {
			if (candidate != chars) result = `${chars}${str}`;
		}
	}
	else {
		candidate = str.slice(-charsLength);
		if (remove) {
			if (candidate == chars) result = str.slice(0, -charsLength);
		}
		else {
			if (candidate != chars) result = `${str}${chars}`;
		}
	}
	return result;
}

export const addTrailingSlash = function(str: string): string {
	return addOrRemoveTrailingOrLeadingChars(str)
};

export const removeTrailingSlash = function(str: string): string {
	return addOrRemoveTrailingOrLeadingChars(str, '/', true);
};

export const extractFileExtension = function(filename: string, keepDot?: boolean): string {
	let dot = filename.lastIndexOf('.');
	let adjust = (keepDot)? 0 : 1;
	return (dot > -1)? filename.substr(dot + adjust).toLowerCase() : '';
};

export const extractBasename = function(filename: string): string {
	let dot = filename.lastIndexOf('.');
	return (dot > -1)? filename.substr(0, dot) : filename;
};

export const extractSlug = function(url: string): string {
	let str = (typeof url !== 'undefined')? url : location.pathname;
	return removeTrailingSlash(str.substring(str.lastIndexOf('/', str.length - 2) + 1));
}

export const iOS = function(): boolean {
	var iDevices = [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod'
	];
	if (!!navigator.platform) {
		while (iDevices.length) {
			if (navigator.platform === iDevices.pop()){ return true; }
		}
	}
	return false;
};

export const isChrome = function(): boolean {
	// please note,
	// that IE11 now returns undefined again for window.chrome
	// and new Opera 30 outputs true for window.chrome
	// but needs to check if window.opr is not undefined
	// and new IE Edge outputs to true now for window.chrome
	// and if not iOS Chrome check
	let isChromium = window.hasOwnProperty('chrome');
	let winNav = window.navigator;
	let vendorName = winNav.vendor;
	let isOpera = window.hasOwnProperty('opr');
	let isIEedge = winNav.userAgent.indexOf('Edg') > -1;
	let isIOSChrome = winNav.userAgent.match('CriOS');

	if (isIOSChrome) {
		// is Google Chrome on IOS
		return false;
	} else if(
		typeof isChromium != 'undefined' &&
		isChromium !== null &&
		vendorName === 'Google Inc.' &&
		isOpera === false &&
		isIEedge === false
	) {
		// is Google Chrome
		return true;
	} else {
		// not Google Chrome
		return false;
	}
}

export const hasTouch = function(): boolean {
	return 'ontouchstart' in document.documentElement
	|| navigator.maxTouchPoints > 0;
};

// Détecte les mobiles mais pas les tablettes.
export const isMobileDevice = () => {
	// https://stackoverflow.com/a/11381730
	let agent = navigator.userAgent;
	let regex1 =
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;

	let regex2 =
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;

	return regex1.test(agent) || regex2.test(agent.substring(0, 4));
}

export const translateFilesizeToBytes = function(str: string|number): number|null {
	if (typeof str === 'string') {
		if (/^\d+k[bo]?/i.test(str)) {
			return (parseFloat(str) * 1024);
		}
		else if (/^\d+m[bo]?/i.test(str)) {
			return (parseFloat(str) * 1048576);
		}
		else if (/^\d+g[bo]?/i.test(str)) {
			return (parseFloat(str) * 1073741824);
		}
		if (isNumeric(str)) {
			return parseFloat(str);
		}
		return null
	}
	return str;
};

export const uid = function(): string {
	let d = new Date().getTime();
	let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		let r = (d + Math.random()*16)%16 | 0;
		d = Math.floor(d/16);
		return (c=='x' ? r : (r&0x3|0x8)).toString(16);
	});
	return uuid;
};

export const clone = function(obj: any): any {
	// Handle the 3 simple types, and null or undefined
	if (null == obj || 'object' != typeof obj) return obj;
	// Handle Date
	if (obj instanceof Date) {
		let copy = new Date();
		copy.setTime(obj.getTime());
		return copy;
	}
	// Handle Array
	if (obj instanceof Array) {
		let copy: any[] = [];
		for (let i = 0, len = obj.length; i < len; i++) {
			copy[i] = clone(obj[i]);
		}
		return copy;
	}
	// Handle Object
	if (obj instanceof Object) {
		const props = Object.getOwnPropertyDescriptors(obj)
		for (const prop in props) {
			props[prop].value = clone(props[prop].value)
		}
		return Object.create(
			Object.getPrototypeOf(obj),
			props
		)
	}
	throw new Error('Unable to copy obj! Its type isn\'t supported: ' + typeof obj);
}

export const simpleArrayFilter = function(arrayOfObj: any[], attr: string, value: any): any[] {
	const results: any[] = [];
	if (!!!value) return arrayOfObj;
	arrayOfObj.forEach(one => {
		if (one.hasOwnProperty(attr) && one[attr].toLowerCase().indexOf(value.toLowerCase()) >= 0) {
			results.push(one);
		}
	});
	return results;
}

export const arrayOfObjectsToObject = function(arrayOfObj: any[], attr: string) {
	let tmp: any = {};
	arrayOfObj.forEach((one: any) => {
		tmp[one[attr]] = one;
	});
	return tmp;
}

export const uniqBy = function(arrayOfObj: any[], identifierFunc: Function, first: boolean = true) {
		let seen = new Set();
		if (first) {
			return arrayOfObj.filter((item: any) => {
				let key: any = identifierFunc(item);
				return seen.has(key) ? false : seen.add(key);
			});
		}

		return [
			...new Map(
				arrayOfObj.map((x: any) => [identifierFunc(x), x])
			).values()
		];
}

export const numericSort = function(array: number[]|any[], reverse?: boolean, attr?: string): void {
	array.sort((a, b) => {
		const left = (reverse)? b : a;
		const right = (reverse)? a : b;
		if (!!attr) {
			if (left[attr] === null && right[attr] === null) return 0;
			if (
				left[attr] === null
				|| left[attr] < right[attr]
			) return -1;
			if (
				right[attr] === null
				|| left[attr] > right[attr]
			) return 1;
		}
		else {
			if (left === null && right === null) return 0;
			if (
				left === null
				|| left < right
			) return -1;
			if (
				right === null
				|| left > right
			) return 1;
		}
		return 0;
	});
}

export const stringSort = function(array: any[], attr: string, reverse?: boolean): void {
	array.sort((a, b) => {
		const left = (reverse)? b : a;
		const right = (reverse)? a : b;
		if (typeof left[attr] == 'undefined' || typeof right[attr] == 'undefined') return 0;
		return left[attr].localeCompare(right[attr]);
	});
}

export const hasDuplicates = function(array: any[], attr?: string): boolean {
	let tmp = array;

	if (attr) {
		tmp = array.map((one: any) => {
			if (typeof one[attr] != 'undefined') return one[attr];
		});
	}

	let duplicates = tmp.reduce((checked: any[], currentValue: any, index: number, duplicates: any[]) => {
		if (
			duplicates.indexOf(currentValue) != index
			&& !checked.includes(currentValue)
		) {
			checked.push(currentValue);
		}
		return checked;
	}, []);
	return !!duplicates.length;
}

export const padString = function(str: string, pad: string, length: number, toRight?: boolean): string {
	if (toRight) {
		return (str.length < length)? padString(str + pad, pad, length, toRight) : str;
	}
	return (str.length < length)? padString(pad + str, pad, length) : str;
}

export const capitalize = function(str: string|null, allWords?: boolean): string|null {
	if (str) {
		if (allWords) {
			return str.split(' ').map(x => x[0].toUpperCase() + x.substr(1)).join(' ');
		}
		return str.charAt(0).toUpperCase() + str.substr(1);
	}
	return str;
}

export const simpleEmailValidation = function(email: string): boolean {
	const re = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA_Z0-9-]*[a-zA-Z0-9])?$/;
	return re.test(String(email).toLowerCase());
}

export const startOfMonth = function(aDate: Date): Date {
	return startOrEndOfMonth(aDate);
}

export const endOfMonth = function(aDate: Date): Date {
	return startOrEndOfMonth(aDate, true);
}

export const startOrEndOfMonth = function(aDate: Date, end?: boolean): Date {
	if (end) {
		return endOfDay(new Date(aDate.getFullYear(), aDate.getMonth() + 1, 0));
	}
	return startOfDay(new Date(aDate.getFullYear(), aDate.getMonth(), 1));
}

export const startOfDay = function(aDate: Date): Date {
	return startOrEndOfDay(aDate);
}

export const endOfDay = function(aDate: Date): Date {
	return startOrEndOfDay(aDate, true);
}

export const startOrEndOfDay = function(aDate: Date, end?: boolean): Date {
	let tmp = new Date(aDate);
	if (end) tmp.setHours(23,59,59,999);
	else tmp.setHours(0,0,0,0);
	return tmp;
}

export const arrayOfMonths = function(year: number, x: number, start?: number): Date[] {
	let offset = 0;
	if (start) {
		offset = start;
	}
	return Array.from({length: x}, (v, k) => {
		return new Date(year, k + offset);
	});
}

export const monthFromNumber = function(month: number): Date {
	const months = arrayOfMonths(1970, 12);
	return months[month -1]
}

export const simpleUTCDateToString = function(aDate: Date|string, short?: boolean, dmy?: boolean): string {
	if (aDate instanceof Date) {
		let d = padString(aDate.getUTCDate().toString(), '0', 2);
		let m = padString((aDate.getUTCMonth() + 1 ).toString(), '0', 2);
		let y = aDate.getUTCFullYear().toString();
		if (short) {
			if (dmy) {
				return `${d}/${m}/${y}`;
			}
			return `${y}-${m}-${d}`;
		}
		return `${y}-${m}-${d}T00:00:00Z`;
	}
	return aDate;
}

export const simpleUTCDateTimeToString = function(aDate: Date|string, dmy?: boolean): string {
	if (aDate instanceof Date) {
		let d = padString(aDate.getUTCDate().toString(), '0', 2);
		let m = padString((aDate.getUTCMonth() + 1 ).toString(), '0', 2);
		let y = aDate.getUTCFullYear().toString();
		let h = padString((aDate.getUTCHours() ).toString(), '0', 2);
		let min = padString((aDate.getUTCMinutes() ).toString(), '0', 2);
		let s = padString((aDate.getUTCSeconds() ).toString(), '0', 2);
		let datePart ;
		datePart = (dmy)? `${d}/${m}/${y}` : `${y}-${m}-${d}`;
		return `${datePart}T${h}:${min}:${s}Z`;
	}
	return aDate;
}

export const simpleDateToString = function(aDate: Date|string, short?: boolean, dmy?: boolean, utc?: boolean): string {
	if (utc) return simpleUTCDateToString(aDate, short, dmy);
	if (aDate instanceof Date) {
		let d = padString(aDate.getDate().toString(), '0', 2);
		let m = padString((aDate.getMonth() + 1 ).toString(), '0', 2);
		let y = aDate.getFullYear().toString();
		if (short) {
			if (dmy) {
				return `${d}/${m}/${y}`;
			}
			return `${y}-${m}-${d}`;
		}
		return `${y}-${m}-${d}T00:00:00Z`;
	}
	return aDate;
}

export const dateUTCToDateCurrentTimezone = function(aDate: Date|string): Date {
	if (!isDate(aDate)) {
		throw new Error('Not a date: '+ aDate.toString());
	}
	let tmpDate: Date = new Date(aDate);
	return new Date(tmpDate.getUTCFullYear(), tmpDate.getUTCMonth(), tmpDate.getUTCDate());
}

export const getCurrentPivotedDate = function(currentDate: Date = new Date(), pivotDay: number = 15): Date {
	let pivotDate = new Date(currentDate);
	pivotDate.setDate(pivotDay);
	pivotDate = startOfDay(pivotDate);
	// if current date is after pivot date, take the month that's before pivot date
	// force previous month if we're on january
	if (currentDate < pivotDate || currentDate.getMonth() == 0) {
		currentDate.setDate(0);
		return startOfDay(startOfMonth(currentDate));
	}
	return currentDate;
}

export const escapeRegExp = function(str: string) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const searchAttrs = function(obj: any, attrs: string[], search: string = '', caseSensitive: boolean = false): boolean {
	let flags = caseSensitive? '' : 'i';
	const escaped = escapeRegExp(search);
	const re = new RegExp(`.*${escaped}.*`, flags);
	for (let i = 0; i < attrs.length; i++) {
		if (obj[attrs[i]] && re.test(obj[attrs[i]])) {
			return true;
		}
	}
	return false;
}

export const calculateMonthPriorDay = function(currentDate: Date, end: Date, pivotDay: number = 15): Date {
	if (currentDate < end) {
		let pivotDate = new Date(currentDate);
		pivotDate.setDate(pivotDay);
		pivotDate = startOfDay(pivotDate);
		// if current date is after pivot date, take the month that's before pivot date
		if (currentDate < pivotDate) {
			end = startOfMonth(currentDate);
			end.setDate(0);
		}
		else end = endOfMonth(currentDate);
	}
	return end;
}

export const convertDateFields = function(obj: any, toDate?: boolean, utc?: boolean, short?: boolean): void {
	const dateAttributesRegex: RegExp = /(^|_)date(_|$)/;
	const dateAttributes: string[] = [
		'ffc_validite_debut',
		'ffc_validite_fin',
		'ffo_validite_debut',
		'ffo_validite_fin',
		'uti_derniere_connexion',
	];

	for (let attr in obj) {
		if (obj.hasOwnProperty(attr)) {
			if (obj[attr] instanceof Object) {
				convertDateFields(obj[attr], toDate, utc);
			}
			if (obj[attr] instanceof Array) {
				for (let i = 0, len = obj[attr].length; i < len; i++) {
					if (obj[attr][i] instanceof Object) {
						convertDateFields(obj[attr][i], toDate, utc);
					}
				}
			}
			else {
				if (attr.match(dateAttributesRegex) || dateAttributes.indexOf(attr) > -1) {
					if (toDate && isDateString(obj[attr])) {
						if (!utc && obj[attr].indexOf('+') > -1) {
							// remove timezone
							obj[attr] = obj[attr].substr(0, obj[attr].indexOf('+'));
						}
						obj[attr] = new Date(obj[attr]);
					}
					else if (!toDate && obj[attr] instanceof Date) {
						obj[attr] = simpleDateToString(obj[attr], short, false, utc);
					}
				}
			}
		}
	}
}

export const simpleDateCompare = function(a: Date, b: Date) {
	let aDate = Math.floor(a.valueOf()/86400000);
	let bDate = Math.floor(b.valueOf()/86400000);
	return (aDate < bDate)? -1 : (aDate > bDate)? 1 : 0;
}

export const convertDateFieldsToDate = function(obj: any, utc?: boolean, short?: boolean): void {
	return convertDateFields(obj, true, utc, short);
}

export const convertDateFieldsToString = function(obj: any, utc?: boolean, short?: boolean): void {
	return convertDateFields(obj, false, utc, short);
}

export const downloadUrl = function(url: string, filename?: string): void {
	let a = document.createElement('a');
	document.body.appendChild(a);
	a.setAttribute('style', 'display: none');
	a.href = url;
	a.download = (filename)? `${filename}` : '';
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}

export const getFilenameFromHttpResponse = function(httpResponse: any): string {
	let contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
	let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
	return result.replace(/"/g, '');
}

export const createDownloadFromHttpResponse = function(httpResponse: any): void {
	const url = window.URL.createObjectURL(new Blob([httpResponse.body]));
	const filename = getFilenameFromHttpResponse(httpResponse);
	downloadUrl(url, filename);
}

export const downloadFromHttpResponse = function(httpResponse: any): Observable<any> {
	return new Observable<any>((subscriber: Observer<any>) => {
		try {
			const url = window.URL.createObjectURL(new Blob([httpResponse.body]));
			const filename = getFilenameFromHttpResponse(httpResponse);
			downloadUrl(url, filename);
			subscriber.next(true);
			subscriber.complete();
		}
		catch(error: any) {
			subscriber.error(error);
		}
	});
}

export const copyToClipboard = function(content: string): boolean {
	let el = document.createElement('textarea');
	document.body.appendChild(el);
	el.setAttribute('style', 'display: none');
	el.setAttribute('id', 'copy');
	el.value = content;
	try {
		let iosCopyToClipboard = function(el: any) {
			let oldContentEditable = el.contentEditable,
				oldReadOnly = el.readOnly,
				range = document.createRange();
			el.contentEditable = true;
			el.readOnly = false;
			range.selectNodeContents(el);
			let s = window.getSelection();
			if (s) {
				s.removeAllRanges();
				s.addRange(range);
			}
			el.setSelectionRange(0, 999999);
			el.contentEditable = oldContentEditable;
			el.readOnly = oldReadOnly;
			document.execCommand('copy');
		};
		let copyListener = function(event: any) {
			document.removeEventListener('copy', copyListener, true);
			event.preventDefault();
			let clipboardData = event.clipboardData;
			clipboardData.clearData();
			clipboardData.setData('text/plain', content);
			clipboardData.setData('text/html', content);
		};
		iosCopyToClipboard(document.getElementById('copy')); // iOS workaround
		document.addEventListener('copy', copyListener, true);
		document.execCommand('copy');
	}
	catch(e) {
		console.log('could not copy to clipboard', e);
		return false;
	}
	finally{
		el.remove();
	}
	return true;
}

export const periodicites: any[] = [
	{value: 1, m: 'Mensuel', f: 'Mensuelle', u: 'Mois'},
	{value: 3, m: 'Trimestriel', f: 'Trimestrielle', u: 'Trimestre'},
	{value: 6, m: 'Semestriel', f: 'Semestrielle', u: 'Semestre'},
	{value: 12, m: 'Annuel', f: 'Annuelle', u: 'Année'},
];

// returns an array of objects from array1 that are included in array2
// optionaly based on the value of an attribute
// deduplicated
export const intersect = function(array1: any[], array2: any[], attr?: string): any[] {
	let tmp = array1.filter((item: any) => {
		if (attr) {
			return array2.some((one: any) => { return item[attr] == one[attr]; });
		}
		return array2.indexOf(item) > -1;
	});
	return dedup(tmp, attr);
}

// returns true if array1 contains at least one item from array2
// optionaly based on the value of an attribute
export const intersects = function(array1: any[], array2: any[], attr?: string): boolean {
	return array1.some((item: any) => {
		if (attr) {
			return array2.some((one: any) => { return item[attr] == one[attr]; });
		}
		return array2.indexOf(item) > -1;
	});
}

// depup array
// optionaly based on the value of an attribute
export const dedup = function(array: any[], attr?: string): any[] {
	return array.filter((item: any, index: number, array: any[]) => {
		if (attr) {
			return array.findIndex((one: any) => { return item[attr] == one[attr]; }) == index;
		}
		return array.indexOf(item) === index;
	});
}

export const mergeDeep = function(target: any, ...sources: any[]): any {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}
	return mergeDeep(target, ...sources);
}

export function isWritable<T extends Object>(obj: T, key: keyof T) {
	const desc = Object.getOwnPropertyDescriptor(obj, key) || {};
	return Boolean(desc.writable)
		&& Object.isFrozen(obj[key])
		&& Object.isExtensible(obj[key])
	;
}

export const sumOnAttr = function(dest: any, attr: string, value: number) {
	if (!dest.hasOwnProperty(attr)) {
		dest[attr] = 0;
	}
	dest[attr] += value;
}

export const sumSameAttr = function(dest: any, source: any, attr: string, initializeWithValue?: any) {
	if (!dest.hasOwnProperty(attr) && typeof initializeWithValue != 'undefined') {
		dest[attr] = initializeWithValue;
	}
	if (source.hasOwnProperty(attr) && typeof source[attr] != 'undefined' && source[attr] !== null) {
		sumOnAttr(dest, attr, source[attr]);
	}
}

export const calculateTaux = function(obj: any, attr1: string, attr2: string, destination?: string, hundred?: boolean): number|null {
	let finalAttr = (destination)? destination : `taux_${attr1}_${attr2}`;
	if (isNumber(obj[attr1]) && isNumber(obj[attr2])) {
		if (obj[attr2] === 0) {
			obj[finalAttr] = (obj[attr1] === 0)? 0 : 1;
		}
		else obj[finalAttr] = obj[attr1] / obj[attr2];
		if (hundred) {
			obj[finalAttr] = obj[finalAttr] * 100;
		}
	}
	else {
		obj[finalAttr] = null;
	}
	return obj[finalAttr];
}

export const calculateEvolution = function(obj: any, attr1: string, attr2: string, destination?: string, hundred?: boolean): number |null {

	let finalAttr = (destination)? destination : `evolution_${attr1}_${attr2}`;

	if (isNumber(obj[attr1]) && isNumber(obj[attr2])) {

		if (obj[attr2] === 0) {
			obj[finalAttr] = 1;
		}
		else {
			let tmp = (obj[attr1] - obj[attr2]) / obj[attr2];
			// limit decimals
			tmp = Math.round((tmp + Number.EPSILON) * 10000) / 10000;
			obj[finalAttr] = tmp;
		}
		if (hundred) {
			obj[finalAttr] = obj[finalAttr] * 100;
		}
	}
	else {
		obj[finalAttr] = null;
	}
	return obj[finalAttr];
}

export const urlType = function(url: string): string {
	if (url) {
		// special links
		let match = url.match(/^(mailto|tel|export|action):/);
		if (match) {
			return match[1];
		}
		// internal
		if (
			!url.startsWith('http')
			|| url.startsWith(window.location.origin)
		) return 'internal';
		// external
		if (url.startsWith('http')) return 'external';
	}
	return 'unknown';
}

export const stripHTMLTags = function(str: string|HTMLElement): string {
	let tmpNode: HTMLElement = document.createElement('div');
	if (typeof str == 'string') {
		tmpNode.innerHTML = str;
	}
	else {
		tmpNode = str;
	}
	return tmpNode? tmpNode.innerText : '';
}

export const isHTMLEmpty = function(str: string|HTMLElement): boolean {
	let tmp = stripHTMLTags(str);
	tmp = tmp.trim();
	return !!!tmp.length;
}

export const addUnbreakableSpaces = function(str: string): string {
	// add space before some symboles
	str = str.replace(/([a-zA-Z0-9])([:?!])/g, '$1 $2');
	// replace with unbreakable space
	str = str.replace(/ +([;:?!\/])/g, ' $1');
	return str;
}

export const trimHTML = function(str: string|HTMLElement|null): string {
	if (!!!str) return '';
	let tmpNode: HTMLElement = document.createElement('div');
	if (typeof str == 'string') {
		tmpNode.innerHTML = str;
	}
	else {
		tmpNode = str;
	}
	let treeWalker: TreeWalker = document.createTreeWalker(tmpNode, NodeFilter.SHOW_ELEMENT);
	let currentNode: Node|null = null;
	let emptyNodes: Node[] = [];

	// find all empty nodes from the beginning and stop on first non-empty node
	currentNode = treeWalker.firstChild();
	while (currentNode) {
		if (!isHTMLEmpty(currentNode as HTMLElement)) {
			break;
		}
		emptyNodes.push(currentNode);
		currentNode = treeWalker.nextNode();
	}

	// find all empty nodes from the end and stop on first non-empty node
	currentNode = treeWalker.lastChild();
	while (currentNode) {
		if (!isHTMLEmpty(currentNode as HTMLElement)) {
			break;
		}
		emptyNodes.push(currentNode);
		currentNode = treeWalker.previousNode();
	}

	// remove found empty nodes
	emptyNodes.forEach((node: Node) => {
		if (node.parentNode) {
			node.parentNode.removeChild(node)
		}
	});

	return tmpNode.innerHTML;
}

export const preventEventInteractiveInput = function(event: KeyboardEvent): void {
	if (event.key) {
		if (
			[
				'alt',
				'arrowdown',
				'arrowleft',
				'arrowright',
				'arrowup',
				'backspace',
				'control',
				'delete',
				'end',
				'enter',
				'escape',
				'home',
				'insert',
				'meta',
				'shift',
				'tab',
			].indexOf(event.key.toLowerCase()) !== -1
			|| (event.key.toUpperCase() == 'A' && (event.ctrlKey || event.metaKey))
			|| (event.key.toUpperCase() == 'C' && (event.ctrlKey || event.metaKey))
			|| (event.key.toUpperCase() == 'V' && (event.ctrlKey || event.metaKey))
			|| (event.key.toUpperCase() == 'X' && (event.ctrlKey || event.metaKey))
		) {
			// Allow these keys to act normal
			return;
		}
	}
	if (event.target instanceof HTMLElement) {
		event.target.blur();
	}
	event.preventDefault();
}

export const parseCsv = function(data: any, delimiter: string = ',') {
	const re = new RegExp(`(${delimiter}|\r?\n|\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^;\r\n]*))`, 'gi');
	const result: any[] = [[]];
	let matches: any[]|null = [];
	while ((matches = re.exec(data))) {
		if (matches[1].length && matches[1] !== delimiter) result.push([])
		let tmp: any = (matches[2] !== undefined) ? matches[2].replace(/""/g, '"') : matches[3]
		result[result.length - 1].push(tmp);
	}
	return result
}

export const csvArrayToObject = function(csvArray: any[]) {
	//Take the first line (headers) from the array and remove it from the array.
	const headers = csvArray.shift()
	// Iterate through the rows and reduce each column to an object
	return csvArray.map(row => headers.reduce((acc: any, currentHeader: any, i: number) => ({ ...acc, ...{ [currentHeader]: row[i] } }), {}))
}

export const csvToArrayOfObjects = function(csv: string, delimiter: string = ',') {
	const csvArray = parseCsv(csv, delimiter);
	return csvArrayToObject(csvArray);
}

export const readFileToStream = function(file: any): Promise<any> {
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.readAsArrayBuffer(file)
		reader.onload = () => {
			resolve(reader.result)
		}
	})
}

export const fakeAPIResponse = <T>(value: T, error: boolean = false, delay: number = 150): Observable<T> => {
	let subject = new Subject<T>();

	setTimeout(() => {
		if (error) {
			subject.error(value);
		}
		else {
			subject.next(value);
		}
		subject.complete();
	}, delay);

	return subject;
}

export const expandTreeNodeRecursive = function(node: TreeNode, expand: boolean) {
	node.expanded = expand;
	if (node.children) {
		node.children.forEach((childNode: TreeNode) => {
			expandTreeNodeRecursive(childNode, expand);
		});
	}
}

export const expandTreeData = function(data: TreeNode[], expand: boolean) {
	let tmp = structuredClone(data);
	tmp.forEach((node: TreeNode) => {
	  expandTreeNodeRecursive(node, expand);
	});
	return tmp;
}

export function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

export function normalizeStringForSearch(s: string) {
	return s.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
