import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { NiveauAccessSelectorModule } from '@app/abonne/niveau-acces-selector';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DialogService } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { ExtensibleObject } from '@app/_helpers/utils';
import { AbonneLogoDisplayModule } from '@app/abonne/abonne-logo-display/abonne-logo-display';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { AbonneModule } from '@app/abonne/abonne.component';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { Site } from '@app/site/site.model';
import { SiteService } from '@app/site/site.service';
import { CiviliteSelectorModule } from '@app/utilisateur/civilite-selector';
import { Utilisateur, UtilisateurAbonne, UtilisateurSite } from '@app/utilisateur/utilisateur.model';
import { TranslateService } from '@codeandweb/ngx-translate';
import { GlobalModule } from '@global/global.module';


const REQUIRED_TRANSLATION_KEYS = [
	'abonnes.unlink_confirmation',
	'abonnes.unlink_success',
	'abonnes.access_level_update_success',
	'abonnes.linking_modal_title',
	'sites.link_success',
	'sites.add_to_site_button',
	'sites.unlink_success',
	'sites.unlink_confirmation'
];

@Component({
	selector: 'utilisateur-acces-tab',
	templateUrl: `utilisateur-acces-tab.component.html`
})
export class UtilisateurAccesTabComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() utilisateur: Utilisateur;
	@Input() isAdminView = false;

	initialUtilisateur: Utilisateur;

	selfEditing = false;
	canEditAccessLevelOfSites = false;

	// Vue du gestionnaire d'abonnement
	currentAbonne: Abonne;
	currentUtiAbonne: UtilisateurAbonne;

	constructor(
		private stateService: StateService,
		private abonneService: AbonneService,
		private siteService: SiteService,
		private eventManager: EventManagerService,
		private dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private translationService: TranslateService,
	) {
		this.currentAbonne = this.abonneService.currentAbonneValue!;
	}

	ngOnInit(): void {
		this.isAdminView = this.me.uti_administrateur;
		let utiAbonne = this.currentAbonne.utilisateurs_abonnes.find(utiAbo => utiAbo.uti_id == this.utilisateur.uti_id);

		if (utiAbonne == undefined) {
			throw new Error('invalid state');
		}

		this.currentUtiAbonne = utiAbonne;
		this.initialUtilisateur = structuredClone(this.utilisateur);
		this.selfEditing = this.me.uti_id == this.utilisateur.uti_id;
		this.canEditAccessLevelOfSites = this.me.uti_administrateur || !!this.currentAbonne.utilisateurs_abonnes.find(uti => uti.uti_id == this.me.uti_id && uti.utb_gestion_abonnement);
	}

	editUtilisateurAbonne(abo_id: number, utiAbonne: UtilisateurAbonne){
		this.abonneService.editAbonneUser(this.utilisateur.uti_id, abo_id, {
			nac_id: utiAbonne.nac_id,
			utb_gestion_abonnement: utiAbonne.utb_gestion_abonnement
		})
		.subscribe({
			complete: () => {
				this.eventManager.emit('toast', {
					severity: 'success',
					summary: this.translationService.instant('abonnes.access_level_update_success'),
				});
				this.stateService.go('.', undefined, {reload: '.'});
			}
		});
	}

	editUtilisateurSite(abo_id: number, sit_id: number, utiSite: UtilisateurSite){
		this.siteService.editUtilisateurSite(this.utilisateur.uti_id, abo_id, sit_id, {
			uti_id: this.utilisateur.uti_id,
			nac_id: utiSite.nac_id
		})
		.subscribe({
			complete: () => {
				this.eventManager.emit('toast', {
					severity: 'success',
					summary: this.translationService.instant('abonnes.access_level_update_success'),
				});
				this.stateService.go('.', undefined, {reload: '.'});
			}
		});
	}

	linkUtilisateurToSite(site: Site){
		let nac_id = 1;

		this.siteService.linkUserToSite(this.utilisateur.uti_id, this.currentAbonne.abo_id, site.sit_id, nac_id)
		.subscribe({
			complete: () => {
				this.eventManager.emit('toast', {
					severity: 'success',
					summary: this.translationService.instant('sites.link_success'),
				});

				this.abonneService.refreshCurrentAbonneData()
				.subscribe({
					complete: () => {
						this.stateService.go('.', undefined, {reload: '^'});
					}
				});
			}
		});
	}

	unlinkUtilisateurFromSite(site: Site){
		this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: this.translationService.instant('sites.unlink_confirmation'),
				accept: () => {
					this.siteService.unlinkUserFromSite(this.utilisateur.uti_id, this.currentAbonne.abo_id, site.sit_id)
					.subscribe(
						(response: any) => {
							this.eventManager.emit('toast', {
								severity: 'success',
								summary: this.translationService.instant('sites.unlink_success'),
							});

							this.abonneService.refreshCurrentAbonneData()
							.subscribe({
								complete: () => {
									this.stateService.go('.', undefined, {reload: '^'});
								}
							});
						}
					);
				}
		});
	}


	canEditAccessLevelForAbonne(abonne: Abonne) {
		// Seul l'administrateur et le gestionaire d'abonnement peuvent changer le niveau d'accès.
		return this.me.uti_administrateur || abonne.utilisateurs_abonnes.find(uti => uti.uti_id == this.me.uti_id && uti.utb_gestion_abonnement);
	}

	canEditGestionAbonnementForAbonne(abonne: Abonne) {
		// Seul l'administrateur peut mettre un utilisateur en gestionnaire d'abonnement.
		return this.me.uti_administrateur;
	}

	getUtiSiteForUtilisateursIfExists(site: Site) {
		return site.utilisateurs_sites.find(uti => uti.uti_id == this.utilisateur.uti_id);
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		DropdownModule,
		AbonneSelectorModule,
		AbonneModule,
		CiviliteSelectorModule,
		ToggleButtonModule,
		DividerModule,
		NiveauAccessSelectorModule,
		AbonneLogoDisplayModule
	],
	exports: [UtilisateurAccesTabComponent],
	declarations: [UtilisateurAccesTabComponent]
})
export class UtilisateurAccesTabModule { }
