<h4 class="text-center">
	{{ 'auth.password_change_form_title' | translate }}
</h4>
<form class="p-fluid mb-2" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
	<div class="form-group field">
		<label>
			{{ 'auth.new_password_label' | translate }}
		</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input type="password" style="display: none;" name="damn-you-autocomplete"/>
			<input type="password" style="display: none;" name="damn-you-autocomplete2"/>
			<input type="password" style="display: none;" name="damn-you-autocomplete3"/>
			<p-password
				formControlName="password"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': f.password.dirty && f.password.errors }"
				[placeholder]="'auth.new_password_label' | translate"
				[promptLabel]="'auth.type_new_password' | translate"
				appendTo="body"
				(ngModelChange)="checkCriterias()"
			></p-password>
		</div>
		<div>
			<small>
				{{ 'auth.password_must_be' | translate }}

				<ul class="pl-2 ml-2 mt-0">
					<li [ngClass]="{'text-color-success': f.password.dirty && f.password.valid, 'text-color-danger': f.password.errors && f.password.errors.pattern}">
						{{ 'auth.be_complex_enough' | translate }}
					</li>
					<li [ngClass]="{'text-color-success': criterias.nb_chars.result == 1, 'text-color-danger': criterias.nb_chars.result == 0}">
						{{ 'auth.contain_at_least_N_chars' | translate }}
					</li>
					<li [ngClass]="{'text-color-success': criterias.uppercase.result == 1, 'text-color-danger': criterias.uppercase.result == 0}">
						{{ 'auth.contain_at_least_one_uppercase_letter' | translate }}
					</li>
					<li [ngClass]="{'text-color-success': criterias.lowercase.result == 1, 'text-color-danger': criterias.lowercase.result == 0}">
						{{ 'auth.contain_at_least_one_lowercase_letter' | translate }}
					</li>
					<li [ngClass]="{'text-color-success': criterias.nb_numbers.result == 1, 'text-color-danger': criterias.nb_numbers.result == 0}">
						{{ 'auth.contain_at_least_one_digit' | translate }}
					</li>
				</ul>
			</small>
		</div>
	</div>
	<div class="form-group field">
		<label>
			{{ 'auth.new_password_confirmation_label' | translate }}
		</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input
				pInputText
				type="password"
				formControlName="passwordConfirm"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': submitted && f.passwordConfirm.errors }"
				[placeholder]="'auth.password_confirmation_label' | translate"
			/>
		</div>
		<div *ngIf="f.passwordConfirm.dirty && f.passwordConfirm.errors" class="text-color-danger">
			<small *ngIf="f.passwordConfirm.errors.required">
				{{ 'auth.password_confirmation_required' | translate }}
			</small>
			<small *ngIf="f.passwordConfirm.errors.mustMatch">
				{{ 'auth.passwords_are_different' | translate }}
			</small>
		</div>
	</div>
	<div *ngIf="success">
		<p-message severity="success" text="{{messageSuccess}}"></p-message>
	</div>
	<button
		type="submit"
		pButton
		[label]="'auth.validate_new_password' | translate"
		[disabled]="!changePasswordForm.valid || loading"
		*ngIf="!success"
	></button>
	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>

</form>

<p class="text-center">
	<a uiSref="auth.login">
		{{ 'auth.go_back_to_login_form' | translate }}
	</a>
</p>
