import Quill from 'quill';
import Delta from 'quill';
import Block from 'quill/blots/block';

export const default_font_sizes: any [] = [
	{label: '8px', value: '8px'},
	{label: 'Taille normale', value: '', default: true},
	{label: '20px', value: '20px'},
	{label: '26px', value: '26px'},
	{label: '38px', value: '38px'},
	{label: '48px', value: '48px'},
	{label: '72px', value: '72px'},
];
export const default_font_sizes_points: any [] = [
	{label: '8pt', value: '8pt'},
	{label: '9pt', value: '9pt'},
	{label: '10pt', value: '10pt'},
	{label: 'Taille normale', value: '', default: true},
	{label: '12pt', value: '12pt'},
	{label: '13pt', value: '13pt'},
	{label: '14pt', value: '14pt'},
	{label: '16pt', value: '16pt'},
	{label: '18pt', value: '18pt'},
	{label: '20pt', value: '20pt'},
	{label: '22pt', value: '22pt'},
	{label: '24pt', value: '24pt'},
	{label: '26pt', value: '26pt'},
	{label: '28pt', value: '28pt'},
	{label: '36pt', value: '36pt'},
	{label: '48pt', value: '48pt'},
	{label: '72pt', value: '72pt'},
];

export const default_header_sizes: any [] = [
	{label: 'Style normal', value: '', default: true},
	{label: 'Titre 5', value: 5 },
	{label: 'Titre 4', value: 4 },
	{label: 'Titre 3', value: 3 },
	{label: 'Titre 2', value: 2 },
	{label: 'Titre 1', value: 1 },
];

export const quillDisableImageSupport = function(quill: any) {
	// quill.container.ondrop = () => { return false; };
	quill.on('text-change', (delta: Delta, oldDelta: any, source: any) => {
		while (document.querySelector('#editor img')) {
			let img = document.querySelector('#editor img');
			if (img) {
				img.remove();
			}
		}
	});
	quill.clipboard.addMatcher('IMG', (node: any, delta: Delta) => {
		const Delta = Quill.import('delta')
		return new Delta().insert('')
	});
	quill.clipboard.addMatcher('PICTURE', (node: any, delta: Delta) => {
		const Delta = Quill.import('delta')
		return new Delta().insert('')
	});
}

// prevent quill from adding <p> tags like crazy
// https://github.com/quilljs/quill/issues/2905#issuecomment-883724079
export const quillModuledisablePTagMadness = {
	clipboard: {
		matchVisual: false
	}
}

// https://github.com/quilljs/quill/issues/3065#issuecomment-646350074
export const quillUseDivInsteadOfP = function() {
	class DivBlock extends Block {}
	DivBlock.tagName = 'div';
	// true means we overwrite
	Quill.register('blots/block', DivBlock, true);
}

export const quillRegisterFontSizes = function(fontSizes: any) {
	let fontSizeStyle = {
		whitelist: fontSizes.map((one: any) => {return one.value})
	}
	Quill.register(fontSizeStyle, true);
}
