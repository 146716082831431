import { CommonModule } from '@angular/common';
import { Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';

import { Subscription } from 'rxjs';

import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

import { GzPanelMenuModule } from '@app/components/gz-panelmenu';
import { ParametreService } from '@app/parametre/parametre.service';
import { AppMenuService } from '@global/app-menu.service';
import { StorageService } from '@global/storage.service';
import { _, TranslateService } from '@codeandweb/ngx-translate';
import { ExtensibleObject } from '@app/_helpers/utils';

@Component({
	selector: 'app-menu',
	templateUrl: './app-menu.html'
})
export class AppMenuComponent implements OnInit, OnDestroy {

	private subscriptionMenuOpened: Subscription;
	private subscriptionMenuItems: Subscription;
	private subscriptionCurrentSalon: Subscription;
	private subscriptionTranslation: Subscription;
	private body = document.getElementsByTagName('body')[0];

	private breakpointMobile = 768;

	displayAppLogo: boolean = false;
	helpEmail: string;

	items: MenuItem[];

	constructor(
		private appMenuService: AppMenuService,
		private parametreService: ParametreService,
		private stateService: StateService,
		private storageService: StorageService,
		private translate: TranslateService,
	) {
		this.subscriptionMenuOpened = appMenuService.toggleMenu$.subscribe(toggle => this.toggle());
		this.subscriptionMenuItems = appMenuService.menuItems$.subscribe(items => {
			this.subscriptionTranslation?.unsubscribe();
			this.items = structuredClone(items);

			// On traduit les labels.

			this.translateLabels(this.items);
			for (let item of this.items) {
				this.translateLabels(item.items ?? []);
			}

			// this.toggleMenu((items.length && this.body.offsetWidth > this.breakpointMobile)? true : false);
			const sidebar_hidden = this.storageService.get('sidebar_hidden', false);
			// display if menu has items and if it wasn't hidden before
			const hideIt = (items.length && !!!sidebar_hidden)? false : true;
			this.toggleDesktopMenu(hideIt);
		});

		this.displayAppLogo = this.parametreService.getParam('display_app_logo');
		this.helpEmail = this.parametreService.getParam('mailto_help');
	}

	translateLabels(items: MenuItem[]) {
		let translationKeys = items.map(item => item.labelKey).filter(translationKey => translationKey != undefined);

		if (translationKeys.length > 0) {
			this.subscriptionTranslation = this.translate.stream(_(translationKeys)).subscribe((res: ExtensibleObject) => {
				for (let item of items) {
					let wordName = item.labelKey!;
					item.label = res[wordName];
				}
			});
		}
	}

	toggle() {
		this.toggleDesktopMenu(!this.body.classList.contains('sidebar-hidden'), true);
	}

	toggleDesktopMenu(hidden?: boolean, manual?: boolean) {
		this.body.classList.toggle('sidebar-hidden', hidden);
		if (manual) {
			this.storageService.set('sidebar_hidden', this.body.classList.contains('sidebar-hidden'));
		}
	}


	ngOnInit() {
		// this.items = []
	}

	ngOnDestroy() {
		this.subscriptionMenuOpened?.unsubscribe();
		this.subscriptionMenuItems?.unsubscribe();
		this.subscriptionCurrentSalon?.unsubscribe();
	}
}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		GzPanelMenuModule,
		DividerModule,
		ButtonModule,
	],
	exports: [AppMenuComponent],
	declarations: [AppMenuComponent]
})
export class AppMenuModule { }

