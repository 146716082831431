import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { MenuModule } from '@app/primeng-overrides/menu';
import { Table, TableModule } from '@app/primeng-overrides/table';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';

import { Site } from '@app/site/site.model';
import { SiteService } from '@app/site/site.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';
import { StorageService } from '@global/storage.service';


import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'liste-sites',
	templateUrl: `liste-sites.component.html`
})
export class ListeSitesComponent implements OnInit, OnDestroy {

	@ViewChild('tableSites', { static: true }) table: Table;
	@Input() me: Utilisateur;
	@Input() abonne_courant: Abonne;

	values: Site[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = environment.default_rows;

	params: { [key: string]: any } = {
		search: null,
		sit_actif: true
	};

	menuItems: MenuItem[] = [];
	canEdit = false;
	subscriptionLangChange: Subscription;

	constructor(
		private storageService: StorageService,
		private stateService: StateService,
		private siteService: SiteService,
		private translate: TranslateService,
		private abonneService: AbonneService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService
	) {

		this.subscriptionLangChange = this.translate.onLangChange.subscribe(() => {
			this.initializeMenu();
		});
	}

	private initializeMenu() {
		if (this.canEdit) {
			this.menuItems = [
				{
					label: this.translate.instant('common.create_action_label'),
					icon: 'pi pi-plus',
					command: () => {
						this.stateService.go('.create');
					}
				}
			];
		}
	}

	ngOnInit(): void {
		this.canEdit = this.me.uti_administrateur;
		this.initializeMenu();
		this.getParamSet();
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange.unsubscribe();
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);

			const uiParams: any = Object.assign({}, this.stateService.params, this.params, {
				sit_actif: undefined
			});
			this.stateService.go('.', uiParams, { reload: false, location: 'replace', inherit: false });
		}
	}

	resetSearch() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	loadSites(event: LazyLoadEvent) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => {
			this.loading = true;
		});

		const tmpParams = Object.assign({}, event, this.params);

		this.saveParamSet();

		this.siteService
			.getSites(this.abonne_courant.abo_id, tmpParams)
			.subscribe({
				next: ({ sites, total }) => {
					this.totalRecords = total;
					this.values = sites;

					// si on revient sur la liste paginée et que la page en question est vide, on reset
					if (this.values.length === 0 && this.table.first != 0) {
						this.refresh();
					}
				}
			})
			.add(() => {
				this.loading = false;
			});
	}

	deleteSite(site: Site) {
		let message = this.translate.instant('sites.deletion_confirmation', {libelle: '<b>'+site.sit_libelle+'</b>'});

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.siteService.deleteSite(this.abonne_courant.abo_id, site.sit_id).subscribe({
					complete: () => {
						this.eventManager.emit('toast', { severity: 'success', summary: this.translate.instant('sites.deletion_success') });
						this.refresh();
					}
				});
			}
		});
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule
	],
	exports: [ListeSitesComponent],
	declarations: [ListeSitesComponent]
})
export class ListeSiteModule { }
