import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';
import { TranslateService } from '@codeandweb/ngx-translate';


@Component({
	selector: 'niveau-access-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NiveauAccessSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			optionValue="value"
			optionLabel="label"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class NiveauAccessSelectorComponent extends ControlValueAccessorBase<number> implements OnInit, OnDestroy  {

	@Input() name: string;
	@Input('value') innerValue: number;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;

	_placeholder: string = 'Sélectionnez';

	options = [
		{
			labelTranslationKey: 'abonnes.access_level_consulting',
			label: '',
			value: 1,
		},
		{
			labelTranslationKey: 'abonnes.access_level_manage',
			label: '',
			value: 2,
		}
	]

	subscriptionLangChange: Subscription;

	constructor(private translateService: TranslateService) {
		super();

		this.setOptions();

		this.subscriptionLangChange = this.translateService.onLangChange.subscribe(() => {
			this.setOptions();
		});
	}

	ngOnInit() {
		if (this.placeholder != undefined) {
			this._placeholder = this.placeholder;
		}
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange.unsubscribe();
	}

	setOptions() {
		for (let option of this.options) {
			option.label = this.translateService.instant(option.labelTranslationKey);
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule,
		CheckboxModule
	],
	exports: [NiveauAccessSelectorComponent],
	declarations: [NiveauAccessSelectorComponent]
})
export class NiveauAccessSelectorModule { }
