<h4 class="text-center">{{'auth.login_2fa_form_title'|translate}}</h4>
<p class="mb-3">{{'auth.login_2fa_form_success'|translate}}</p>
<form class="p-fluid mb-2" #login2faForm="ngForm">
	<div class="form">

		<div class="field text-center">
			<p-inputOtp
				name="code2fa"
				[(ngModel)]="code2fa"
				[integerOnly]="true"
				[length]="6"
				[required]="true"
				class="inline-flex"
			>
			</p-inputOtp>
		</div>

	</div>

	<button
		type="button"
		pButton
		label="{{'common.validate_button_label'|translate}}"
		[disabled]="!login2faForm.valid || loading"
		(click)="submit()"
	></button>
	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>

	<p class="text-center mt-3">{{'auth.login_2fa_form_not_received_part1'|translate}} <span class="link clickable" (click)="resend()">{{'auth.login_2fa_form_click_here'|translate}}</span>.</p>

</form>

<p class="text-center">
	<button
		type="button"
		pButton
		class="p-button-text"
		label="{{'auth.go_back_to_login_form'|translate}}"
		[disabled]="loading"
		(click)="logout()"
	></button>
</p>
