import { NgModule, Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';
import { UIRouter, PathNode, Transition } from '@uirouter/core';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@codeandweb/ngx-translate';

import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { Breadcrumb } from '@global/breadcrumbs.module';

import { uid } from '@helpers/utils';

@Component({
	selector: 'app-breadcrumbs',
	template: `
		<div class="p-breadcrumb p-component flex justify-content-between">
			<ul class="breadcrumbs">
				<ng-template ngFor let-item let-first="first" let-last="last" [ngForOf]="crumbs">
					<li [ngClass]="{'p-disabled': item.disabled, 'last-breadcrumb': last}">
						<a
							*ngIf="item.state && !item.abstract"
							[uiSref]="item.state"
							[queryParams]="item.queryParams"
							class="p-menuitem-link"
							[attr.target]="item.target"
							[attr.title]="item.title"
							[attr.id]="item.id"
							[attr.tabindex]="item.disabled ? null : '0'"
							[fragment]="item.fragment"
							[queryParamsHandling]="item.queryParamsHandling"
							[preserveFragment]="item.preserveFragment"
							[skipLocationChange]="item.skipLocationChange"
							[replaceUrl]="item.replaceUrl"
							[state]="item.state"
							[uiOptions]="{ reload: '.' }"
						>
							<!--<span *ngIf="first" class="p-menuitem-icon pi pi-home"></span>-->
							<!--<ng-container *ngIf="item.label && !first">-->
							<ng-container *ngIf="item.label">
								<span *ngIf="item.escape !== false; else htmlRouteLabel" class="p-menuitem-text">{{item.label | translate}}</span>
								<ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span></ng-template>
							</ng-container>
						</a>
						<span *ngIf="item.state && item.abstract">
							<span *ngIf="first" class="p-menuitem-icon pi pi-home" ></span>
							<!--<ng-container *ngIf="item.label && !first">-->
							<ng-container *ngIf="item.label">
								<span *ngIf="item.escape !== false; else htmlRouteLabel" class="p-menuitem-text">{{item.label | translate}}</span>
								<ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span></ng-template>
							</ng-container>
						</span>
					</li>
					<li class="p-breadcrumb-chevron pi pi-chevron-right" *ngIf="!last"></li>
				</ng-template>
			</ul>
		</div>
	`,
	styles: [`
		.p-breadcrumb ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.p-breadcrumb .p-menuitem-text {
				line-height: 1;
		}

		.p-breadcrumb .p-menuitem-link {
				text-decoration: none;
		}
	`]
})
export class AppBreadcrumbsComponent implements IEventListener, OnDestroy {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private stateChangeUnsubscribe: Function;
	public crumbs: Breadcrumb[] = [];

	onDashboard: boolean;
	baseTitle: string;

	constructor(
		private eventManager: EventManagerService,
		private uiRouter: UIRouter,
	) {

		this.updateCrumbs(this.uiRouter.globals.successfulTransitions.peekTail());
		this.stateChangeUnsubscribe = this.uiRouter.transitionService.onSuccess({}, trans => this.updateCrumbs(trans));

	}

	ngOnDestroy() {
		this.stateChangeUnsubscribe();
		this.eventManager.unregisterEvent('logout', this);
	}

	private updateCrumbs(trans: Transition) {
		this.crumbs = trans.treeChanges('to')
		.filter(node => node.state.breadcrumb)
		.map(node => {
			let state: string = (typeof node.state.redirectTo == 'string')? node.state.redirectTo : node.state.name;
			return {
				state: state,
				abstract: node.state.abstract,
				label: node.state.breadcrumb?.(trans)
			}
		});

		this.onDashboard = (this.crumbs.length == 1);
	}

}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		UIRouterModule
	],
	exports: [AppBreadcrumbsComponent],
	declarations: [AppBreadcrumbsComponent]
})
export class AppBreadcrumbsModule { }
