import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { Subscription } from 'rxjs';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { Rubrique } from '@app/nomenclature/nomenclature.model';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';
import { stringSort } from '@helpers/utils';

@Component({
	selector: 'rubrique-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RubriqueSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[filter]="options.length > 5"
			filterBy="rub_nom"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			[virtualScroll]="true"
			[virtualScrollItemSize]="virtualScrollItemSize"
			optionValue="rub_id"
			optionLabel="rub_nom"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class RubriqueSelectorComponent extends ControlValueAccessorBase<number> implements OnInit, OnDestroy  {

	@Input() name: string;
	@Input('value') innerValue: number;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;
	@Input() unavailableRubriques?: number[];
	@Input() fam_id: number;
	@Input() thm_id: number;

	_placeholder: string = 'Sélectionnez';

	options: {rub_id: number, rub_nom: string}[] = [];

	subscriptionLangChange?: Subscription;

	// TODO on devrait centraliser ça
	virtualScrollItemSize: number = 18;

	constructor(private nomenclatureService: NomenclatureService, private translateService: TranslateService) {
		super();

		if (this.placeholder == undefined) {
			this.subscriptionLangChange = this.translateService.onLangChange.subscribe(() => {
				this._placeholder = translateService.instant('common.selector_placeholder');
			});
		}
	}

	ngOnInit() {
		if (this.placeholder != undefined) {
			this._placeholder = this.placeholder;
		}
		this.refresh();
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange?.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.fam_id && changes.fam_id.currentValue != changes.fam_id.previousValue
			|| changes.thm_id && changes.thm_id.currentValue != changes.thm_id.previousValue
		) {
			this.refresh();
		}
	}

	refresh() {
		this.nomenclatureService.getCacheNomenclature()
		.subscribe(({ rubriques }) => {
			let tmp: any = rubriques.filter((rubrique: Rubrique) => rubrique.rub_actif);
			stringSort(tmp, 'rub_nom');

			if (this.unavailableRubriques != undefined) {
				tmp = tmp.filter((rubrique: Rubrique) => !this.unavailableRubriques?.includes(rubrique.rub_id))
			}

			if (this.fam_id != undefined) {
				tmp = tmp.filter((rubrique: Rubrique) => rubrique.fam_id == this.fam_id);
			}

			if (this.thm_id != undefined) {
				tmp = tmp.filter((rubrique: Rubrique) => rubrique.thm_id == this.thm_id);
			}

			this.options = [...tmp];
		});
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule
	],
	exports: [RubriqueSelectorComponent],
	declarations: [RubriqueSelectorComponent]
})
export class RubriqueSelectorModule { }
