<form #profilForm="ngForm">
	<fieldset [disabled]="loading || disconnecting">
		<div class="p-fluid formgrid grid">
			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_email">{{'common.email_label'|translate}}</label>
				<input
					name="uti_email"
					#uti_email
					[(ngModel)]="utilisateur.uti_email"
					type="email"
					required
					email="true"
					pInputText
					[ngClass]="{ 'p-invalid': submitted && uti_email.errors }"
					placeholder="email@exemple.com"
				>
				<div *ngIf="submitted && uti_email.errors" class="invalid-feedback p-invalid">
					<small *ngIf="uti_email.errors.required">{{'auth.email_required'|translate}}</small>
					<small *ngIf="uti_email.errors.email">{{'auth.invalid_email_address'|translate}}</small>
				</div>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_civilite">{{'users.salutation_label'|translate}}</label>
				<civilite-selector
					name="uti_civilite"
					#uti_civilite
					[(ngModel)]="utilisateur.uti_civilite"
					required
				></civilite-selector>
				<div *ngIf="submitted && uti_civilite.errors" class="invalid-feedback p-invalid">
					<small *ngIf="uti_civilite.errors.required">{{'common.required_field'|translate}}</small>
				</div>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_nom">{{'users.last_name_label'|translate}}</label>
				<input
					name="uti_nom"
					#uti_nom
					[(ngModel)]="utilisateur.uti_nom"
					required
					type="text"
					pInputText
					[ngClass]="{ 'p-invalid': submitted && uti_nom.errors }"
				>
				<div *ngIf="submitted && uti_nom.errors" class="invalid-feedback p-invalid">
					<small *ngIf="uti_nom.errors.required">{{'common.required_field'|translate}}</small>
				</div>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_prenom">{{'users.first_name_label'|translate}}</label>
				<input
					type="text"
					pInputText
					name="uti_prenom"
					#uti_prenom
					[(ngModel)]="utilisateur.uti_prenom"
					required
					[ngClass]="{ 'p-invalid': submitted && uti_prenom.errors }"
				>
				<div *ngIf="submitted && uti_prenom.errors" class="invalid-feedback p-invalid">
					<small *ngIf="uti_prenom.errors.required">{{'common.required_field'|translate}</small>
				</div>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_telephone_fixe">{{'common.landline_phone_label'|translate}}</label>
				<input
					type="text"
					pInputText
					name="uti_telephone_fixe"
					#uti_telephone_fixe
					[(ngModel)]="utilisateur.uti_telephone_fixe"
				>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_telephone_mobile">{{'common.cellphone_label'|translate}}</label>
				<input
					type="text"
					pInputText
					name="uti_telephone_mobile"
					#uti_telephone_mobile
					[(ngModel)]="utilisateur.uti_telephone_mobile"
				>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_fonction">{{'users.function_label'|translate}}</label>
				<input
					type="text"
					pInputText
					name="uti_fonction"
					#uti_fonction
					[(ngModel)]="utilisateur.uti_fonction"
					placeholder="Votre fonction"
				>
			</div>

			<div class="field col-fixed">
				<label>{{'common.disconnect_all_my_sessions'|translate}}</label>
				<div>
					<button
						type="button"
						pButton
						label="{{'common.disconnect'|translate}}"
						(click)="disconnect()"
						[disabled]="loading"
						[loading]="disconnecting"
					></button>
				</div>
			</div>

		</div>
		<div class="text-center">
			<button
				type="button"
				pButton
				class="p-button-outlined mr-2"
				label="{{'common.cancel'|translate}}"
				[disabled]="loading || disconnecting"
				(click)="cancel()"
			></button>
			<button
				type="button"
				pButton
				[label]="'common.save_button_label' | translate"
				[loading]="loading"
				[disabled]="!profilForm.form.valid || disconnecting"
				(click)="submit()"
			></button>
		</div>
	</fieldset>
</form>
