<p>
	{{'rgpd.text' | translate}}
</p>

<div class="flex justify-content-center">
	<div class="field-checkbox">
		<p-checkbox
			name="accord"
			inputId="accord"
			[(ngModel)]="accord"
			[binary]="true"
			label="{{'rgpd.agreed'|translate}}"
		></p-checkbox>
	</div>
</div>

<div class="text-center">
	<button
		pButton
		type="button"
		[label]="'common.validate_button_label' | translate"
		[loading]="loading"
		(click)="submit()"
		[disabled]="!accord"
	></button>
</div>
