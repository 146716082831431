import { NgModule, Component, OnInit, Input, ViewChild, EventEmitter, ChangeDetectorRef, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateService } from '@uirouter/core';

import { TranslateService } from '@codeandweb/ngx-translate';

import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { FileUploadModule, FileUpload } from '@app/primeng-overrides/fileupload';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';

import { EventManagerService } from '@global/event-manager.service';
import { ParametreService } from '@app/parametre/parametre.service';
import { AbonneLogoDisplayModule } from '@app/abonne/abonne-logo-display/abonne-logo-display';
import { AbonneService } from '../abonne.service';
import { GlobalModule } from '@app/_global/global.module';
import { Abonne } from '../abonne.model';

@Component({
	selector: 'abonne-logo-uploader',
	templateUrl: './abonne-logo-uploader.html'
})
export class LogoUploaderComponent implements OnInit {

	@Input() abonne: Abonne;
	@Input() canEdit: boolean;
	@Output() onSuccessfullUpload = new EventEmitter();

	@ViewChild('uploader') uploader: FileUpload;

	prefix: string;
	acceptedMimeTypes: string;
	acceptedExtensions: string;
	urlUpload: string;
	maxFileSize: number = 10485760;
	error: string|null;
	header: string;

	request$: Subscription|undefined;
	subscriptionLangChange: Subscription;

	invalidFileLimitMessageDetail: string;
	invalidFileLimitMessageSummary: string;
	invalidFileSizeMessageDetail: string;
	invalidFileSizeMessageSummary: string;
	invalidFileTypeMessageDetail: string;
	invalidFileTypeMessageSummary: string;
	//tooltipLogoListing = `Logo dont le ratio hauteur/largeur est adapté pour l'affichage dans des listes et tableaux`;

	constructor(
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private parametreService: ParametreService,
		private stateService: StateService,
		private abonneService: AbonneService,
		private translate: TranslateService,
		private primeNGConfig: PrimeNGConfig
	) {
		this.acceptedMimeTypes = [
			'image/jpeg',
			'image/png',
		].join(',');

		this.acceptedExtensions = [
			'jpg', 'jpeg', 'png'
		].map(one => {return '*.' + one; }).join(', ');

		this.maxFileSize = this.parametreService.getParam('logo_max_file_size_bytes');

		//TODO: utiliser des pipes dans le HTML
		this.subscriptionLangChange = this.translate.onLangChange.subscribe(() => {
			this.invalidFileLimitMessageDetail = this.primeNGConfig.getTranslation('invalidFileLimitMessageDetail');
			this.invalidFileLimitMessageSummary = this.primeNGConfig.getTranslation('invalidFileLimitMessageSummary');
			this.invalidFileSizeMessageDetail = this.primeNGConfig.getTranslation('invalidFileSizeMessageDetail');
			this.invalidFileSizeMessageSummary = this.primeNGConfig.getTranslation('invalidFileSizeMessageSummary');
			this.invalidFileTypeMessageDetail = this.primeNGConfig.getTranslation('invalidFileTypeMessageDetail');
			this.invalidFileTypeMessageSummary = this.primeNGConfig.getTranslation('invalidFileTypeMessageSummary');
			this.invalidFileTypeMessageDetail = this.invalidFileTypeMessageDetail.replace('{0}', this.acceptedExtensions);
		});
	}

	ngOnInit(): void {
		this.urlUpload = this.abonneService.getAbonneLogoUploadURL(this.abonne.abo_id);
	}

	ngOnDestroy(): void {
		this.abort();
		this.subscriptionLangChange.unsubscribe();
	}

	// regular uploader
	upload() {
		this.error = null;
		this.request$ = this.uploader.upload();
	}

	abort() {
		if (this.request$) {
			this.request$.unsubscribe();
			this.eventManager.emit('toast', {severity: 'info', summary: this.translate.instant('abonnes.logo_transfer_aborted')});
			this.request$ = undefined;
			// this.uploader.uploading = false;
			this.uploader.progress = 0;
			this.uploader.cd.markForCheck();
		}
	}

	onUpload(event: any) {
		this.request$ = undefined;
		this.eventManager.emit('toast', {severity: 'success', summary: this.translate.instant('abonnes.logo_updated_success')});
		this.eventManager.emit('logo_change', this.abonne.abo_id);
		this.onSuccessfullUpload.emit();
	}

	onError(event: any) {
		this.request$ = undefined;
	}

	remove(index: number) {
		this.uploader.files.splice(index, 1);
	}


}


@NgModule({
	imports: [
		CommonModule,
		ButtonModule,
		FileUploadModule,
		PanelModule,
		TooltipModule,
		GlobalModule,
		AbonneLogoDisplayModule
	],
	exports: [LogoUploaderComponent],
	declarations: [LogoUploaderComponent]
})
export class LogoUploaderModule { }

