<form class="mb-2" #rubriqueForm="ngForm">

	<div class="p-fluid grid formgrid p-2">

		<div class="field-checkbox col-12">
			<p-checkbox
				name="rub_actif"
				inputId="rub_actif"
				[(ngModel)]="rubrique.rub_actif"
				[binary]="true"
				inputId="binary"
				[label]="'rubriques.active_state' | translate"
				[disabled]="!editMode"
			></p-checkbox>
		</div>

		<!-- Nom -->
		<div class="field col-12">
			<label for="rub_nom">
				{{ 'common.name_label' | translate }}
			</label>
			<input
				type="text"
				pInputText
				name="rub_nom"
				[(ngModel)]="rubrique.rub_nom"
				[attr.placeholder]="'common.name_label' | translate"
				[readOnly]="!editMode"
				required
			/>
		</div>

		<div class="col-12"></div>

		<!-- Famille -->
		<div class="field col-6">
			<label for="thm_nom">
				{{ 'families.family_label' | translate }}
			</label>
			<famille-selector
				name="fam_id"
				[(ngModel)]="rubrique.fam_id"
				(ngModelChange)="onFamilleChange()"
				[readonly]="!editMode"
				required
			>
			</famille-selector>
		</div>


		<!-- Thème -->
		<div class="field col-6">
			<label for="thm_nom">
				{{ 'themes.theme_label' | translate }}
			</label>
			<theme-selector
				name="thm_id"
				[fam_id]="rubrique.fam_id"
				[(ngModel)]="rubrique.thm_id"
				[readonly]="!editMode"
				required
			>
			</theme-selector>
		</div>

		<!-- Privatisation -->
		<div class="field col-6">
			<label for="thm_nom">
				{{ 'rubriques.privatization_label' | translate }}
			</label>
			<abonne-selector
				name="abo_id_privatisation"
				[(ngModel)]="rubrique.abo_id_privatisation"
				[readonly]="!editMode"
				[nullOption]="editMode"
				[placeholder]="'rubriques.not_privatized' | translate"
			>
			</abonne-selector>
		</div>

		<!-- Description -->

		<div class="field col-12">
			<label for="rub_description">
				{{ 'common.description_label' | translate }}
			</label>
			<textarea
				pInputTextarea
				name="rub_description"
				[(ngModel)]="rubrique.rub_description"
				[autoResize]="true"
				[readOnly]="!editMode"
			></textarea>
		</div>

	</div>

	<div class="text-center">
		<!-- Bouton éditer -->
		<button
			*ngIf="!editMode && canEdit"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.edition_button_label' | translate"
			icon="pi pi-pencil"
			(click)="toggleEditMode()"
		></button>

		<!-- Bouton enregistrer -->
		<button
			*ngIf="!!editMode"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.save_button_label' | translate"
			icon="pi pi-save"
			(click)="submit()"
			[disabled]="!rubriqueForm.valid"
		></button>
	</div>
</form>
