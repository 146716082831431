import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, isDevMode, NgModule, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@codeandweb/ngx-translate';

import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';

import { Observable, Subscription } from 'rxjs';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { MenuModule } from '@app/primeng-overrides/menu';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';

import { ParametreService } from '@app/parametre/parametre.service';
import { ProfilDialogComponent } from '@app/utilisateur/profil-dialog';
import { RGPDDialogComponent } from '@app/utilisateur/rgpd-dialog';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurPipe } from '@app/utilisateur/utilisateur.pipe';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { AppMenuService } from '@global/app-menu.service';
import { EN_LANG, FR_LANG } from '@global/constantes-langages';
import { EventManagerService } from '@global/event-manager.service';
import { StorageService } from '@global/storage.service';

import { primengTexts } from '@app/_helpers/primeng-texts';
import { ExtensibleObject } from '@app/_helpers/utils';
import { AbonneLogoDisplayModule } from '@app/abonne/abonne-logo-display/abonne-logo-display';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { GlobalModule } from '../global.module';

@Component({
	selector: 'app-topbar',
	templateUrl: './app-topbar.html',
})
export class AppTopbarComponent implements OnInit, OnDestroy {
	version: string;
	currentUtilisateur: Utilisateur | null;
	isSubscriptionManager = false;
	subscriptionCurrentUtilisateur: Subscription;
	currentUtilisateurMenuItems: MenuItem[];

	currentAbonne?: Abonne;
	subscriptionCurrentAbonne: Subscription;
	accessToSeveralAbonnes = false;

	subscriptionMenuItems: Subscription;
	hasMenu: boolean;

	rgpdDialogShown: boolean;
	instanceName: string;

	subscriptionLangChange: Subscription;

	constructor(
		private appMenuService: AppMenuService,
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private parametreService: ParametreService,
		private stateService: StateService,
		private storageService: StorageService,
		private utilisateurService: UtilisateurService,
		private utilisateurPipe: UtilisateurPipe,
		private abonneService: AbonneService,
		private changeDetectorRef: ChangeDetectorRef,
		private translate: TranslateService,
		private primeNGConfig: PrimeNGConfig,
	) {
		this.instanceName = this.parametreService.getParam('instance_name');
		this.version = 'rev.' + this.parametreService.getParam('version');
		this.rgpdDialogShown = this.storageService.get('rgpdDialogShown');

		this.subscriptionLangChange = this.translate.onLangChange.subscribe(() => {
			this.updateUserMenu();
		});

		this.subscriptionCurrentUtilisateur = this.utilisateurService.currentUtilisateur$
			.subscribe({
				next: (utilisateur: Utilisateur | null) => {
					this.currentUtilisateur = utilisateur ?? null;
					this.accessToSeveralAbonnes = utilisateur != undefined && (utilisateur.uti_administrateur || utilisateur.abonnes.length > 1);
					this.updateSubscriptionManagerStatus();
					this.updateUserMenu();
				},
			});

		this.subscriptionCurrentAbonne = this.abonneService.currentAbonne$
			.subscribe({
				next: (abonne: Abonne) => {
					this.currentAbonne = abonne;
					this.updateSubscriptionManagerStatus();
				},
			});

		this.subscriptionMenuItems = this.appMenuService.menuItems$.subscribe({
			next: (items: MenuItem[]) => {
				this.hasMenu = items.length > 0;
			},
		});

	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		this.subscriptionCurrentUtilisateur.unsubscribe();
		this.subscriptionCurrentAbonne.unsubscribe();
		this.subscriptionMenuItems.unsubscribe();
		this.subscriptionLangChange.unsubscribe();
	}

	toggleMenu() {
		this.appMenuService.toggleMenu();
	}

	logout() {
		this.eventManager.emit('logout', true);
	}

	showMonProfilForm() {
		const ref = this.dialogService.open(ProfilDialogComponent, {
			header: this.translate.instant('user-menu.my-profile'),
			width: '70%',
		});
		ref.onClose.subscribe(dialogResult => {
			if (dialogResult && dialogResult.refreshCurrentUtilisateur == true) {
				this.utilisateurService.getCurrentUtilisateur()
					.subscribe();
			}
		});
	}

	updateSubscriptionManagerStatus(){
		this.isSubscriptionManager = false;
		let currentUtilisateur = this.currentUtilisateur;

		if (this.currentAbonne != undefined && currentUtilisateur != undefined) {
			let utilisateurAbonne = this.currentAbonne.utilisateurs_abonnes.find(utiAbo => utiAbo.uti_id == currentUtilisateur.uti_id);
			if (utilisateurAbonne?.utb_gestion_abonnement) {
				this.isSubscriptionManager  = true;
			}
		}
	}

	goToAbonneSelection(){
		this.currentAbonne = undefined;
		this.stateService.go('selection-abonne-courant')
	}

	toggleLanguage() {
		// On change d'abord la configuration primeNG afin que les bouts de code qui seront appelés par ngx-translate
		// voient déjà le changement de la configuration primeNG.
		let texts: ExtensibleObject = primengTexts;
		let newLanguage = (this.translate.currentLang == EN_LANG)? FR_LANG : EN_LANG;
		this.primeNGConfig.setTranslation(texts[newLanguage]);
		this.translate.use(newLanguage).subscribe()
		.add(() => {
			this.storageService.set('lang', newLanguage, false);
		})
	}

	updateUserMenu() {
		if (this.currentUtilisateur === null) {
			this.currentUtilisateurMenuItems = [{
				label: this.translate.instant('user-menu.disconnect'),
				icon: 'pi pi-sign-out',
				command: () => {
					this.logout();
				},
			}];
		}
		else {
			let items: MenuItem[] = [];

			items.push({
				label: this.translate.instant('user-menu.my-profile'),
				icon: 'pi pi-user-edit',
				command: () => {
					this.showMonProfilForm();
				},
			});

			if (isDevMode()) {
				items.push({
					label: this.translate.instant('user-menu.change-language'),
					icon: 'pi pi-language',
					command: () => {
						this.toggleLanguage()
					},
				});
			}

			items.push({
				separator: true,
			});

			items.push({
				label: this.translate.instant('user-menu.disconnect'),
				icon: 'pi pi-sign-out',
				command: () => {
					this.logout();
				},
			});

			this.currentUtilisateurMenuItems = [
				{
					label: this.utilisateurPipe.transform(this.currentUtilisateur),
					items: items,
				},
			];

			if (this.currentUtilisateur.uti_date_accord_rgpd != null) {
				this.storageService.set('rgpdDialogShown', true);
			}
			else if (
				!this.rgpdDialogShown
				&& this.currentUtilisateur.uti_date_accord_rgpd === null
				&& this.stateService.current.name != 'root.change_password'
			) {
				this.rgpdDialogShown = true;
				const ref = this.dialogService.open(RGPDDialogComponent, {
					header: this.translate.instant('rgpd.dialog-title'),
					width: '70%',
				});
				ref.onClose.subscribe(dialogResult => {
					this.storageService.set('rgpdDialogShown', true);
				});
			}
		}
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		ButtonModule,
		MenuModule,
		CalendarModule,
		AbonneLogoDisplayModule,
		UIRouterModule
	],
	exports: [AppTopbarComponent],
	declarations: [AppTopbarComponent],
})
export class AppTopbarModule {}
