import { UIRouter, Category } from '@uirouter/core';

import * as hooks from '@global/router-transition-hooks';
import { Visualizer } from '@uirouter/visualizer';

export function routerConfigFn(router: UIRouter) {
	const transitionService = router.transitionService;
	const stateService = router.stateService;

	// register transition hooks
	Object.values(hooks).map((hook) => hook(transitionService));

	// allow optional trailing slash on urls. Easier redirectTo this way
	// router.urlMatcherFactory.strictMode(false);

	stateService.defaultErrorHandler(function(error) {
		setTimeout(function(){
			console.log('stateService error handler', error, stateService);
			// todo gérer correctement les types 6
			// RejectType.SUPERSEDED (2)
			// RejectType.ABORTED (3)
			// RejectType.INVALID (4)
			// RejectType.IGNORED (5)
			// RejectType.ERROR (6)
			if (error && error.type != 2) {
				// stay on current state
				if (stateService.current.name) stateService.go(stateService.current.name);
				// otherwise, return to portail
				else stateService.go('root', undefined, {reload: true});
			}
		}, 1000);
	})

	// router.trace.enable(Category.TRANSITION);
	// router.trace.enable(Category.RESOLVE);
	// router.trace.enable(Category.HOOK);

	// router.plugin(Visualizer);
}
