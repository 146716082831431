
<p-toolbar class="" *ngIf="showFiltersOut">
	<ng-template pTemplate="left">

		<div class="grid form-grid fluid">
			<div class="field col-fixed">
				<label for="filter_fam">{{'families.family_label'|translate}}</label>
				<!-- <div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_fam"
						[(ngModel)]="filters.fam"
						placeholder="{{'families.family_label'|translate}}…"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.fam || !filters.fam" (click)="filters.fam = null"></button>
				</div> -->
				<div class="">
					<p-iconField iconPosition="right">
						<p-inputIcon styleClass="pi pi-times clickable" *ngIf="!!filters.fam" (click)="filters.fam = null"/>
						<input
							pInputText
							type="text"
							name="filter_fam"
							[(ngModel)]="filters.fam"
							(onDebounce)="filterChange()"
							placeholder="{{'families.family_label'|translate}}…"
						/>
					</p-iconField>
				</div>
			</div>

			<div class="field col-fixed">
				<label for="filter_thm">{{'themes.theme_label'|translate}}</label>
				<!-- <div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_thm"
						[(ngModel)]="filters.thm"
						placeholder="{{'themes.theme_label'|translate}}…"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.thm || !filters.thm" (click)="filters.thm = null"></button>
				</div> -->
				<div class="">
					<p-iconField iconPosition="right">
						<p-inputIcon styleClass="pi pi-times clickable" *ngIf="!!filters.thm" (click)="filters.thm = null"/>
						<input
							pInputText
							type="text"
							name="filter_thm"
							[(ngModel)]="filters.thm"
							(onDebounce)="filterChange()"
							placeholder="{{'themes.theme_label'|translate}}…"
						/>
					</p-iconField>
				</div>
			</div>

			<div class="field col-fixed">
				<label for="filter_rub">{{'rubriques.rubrique_label'|translate}}</label>
				<!-- <div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_rub"
						[(ngModel)]="filters.rub"
						placeholder="{{'rubriques.rubrique_label'|translate}}…"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.rub || !filters.rub" (click)="filters.rub = null"></button>
				</div> -->
				<div class="">
					<p-iconField iconPosition="right">
						<p-inputIcon styleClass="pi pi-times clickable" *ngIf="!!filters.rub" (click)="filters.rub = null"/>
						<input
							pInputText
							type="text"
							name="filter_rub"
							[(ngModel)]="filters.rub"
							(onDebounce)="filterChange()"
							placeholder="{{'rubriques.rubrique_label'|translate}}…"
						/>
					</p-iconField>
				</div>
			</div>

			<div class="field-checkbox col-fixed align-items-end">
				<p-triStateCheckbox
					name="showInactive"
					label="{{'common.inactive'|translate}}"
					[(ngModel)]="showInactive"
					(onChange)="filterChange()"
					checkboxFalseIcon=""
					[allowNull]="true"
					[trueValue]="false"
					[falseValue]="true"
					[highLightFalse]="false"
					checkboxTrueIcon="pi pi-check"
					checkboxNullIcon="pi pi-minus"
					[highLightNull]="true"
					class="mb-2"
					*ngIf="showInactiveFilter"
				></p-triStateCheckbox>

				<!-- <button
					type="button"
					pButton
					icon="pi pi-refresh"
					class="p-button-rounded p-button-text p-button-sm p-button-danger"
					[loading]="loading"
					(click)="load()"
				></button> -->
			</div>

			<!-- <div class="field col-fixed flex align-items-end">
				<button
					type="button"
					pButton
					class="p-button-text p-button-sm"
					[disabled]="filters.fam == null && filters.thm == null && filters.rub == null && showInactive == false"
					label="{{'common.reset_filters'|translate}}"
					(click)="resetFilter()"
				></button>
			</div> -->
		</div>


	</ng-template>
</p-toolbar>

<p-treeSelect
	#treeSelect
	*ngIf="treeSelect"
	class="block"
	panelStyleClass="treenode-auto"
	containerStyleClass="w-full"
	[(ngModel)]="selected"
	[options]="hierarchy"
	placeholder="{{ placeholder || 'common.selector_placeholder' | translate }}"
	[virtualScroll]="true"
	[virtualScrollItemSize]="virtualScrollItemSize"
	[virtualScrollOptions]="{scrollHeight: '200px'}"
	[filter]="true"
	[showClear]="true"
	(ngModelChange)="selectionChange()"
	[appendTo]="appendSelectToBody ? 'body' : undefined"
>
	<ng-template let-value pTemplate="value">
		<div class="white-space-nowrap overflow-hidden text-overflow-ellipsis" *ngIf="value" title="{{value.data|nomenclature}}">
			<!-- {{value.label}} -->
			{{value.data|nomenclature}}
		</div>
		<div>
			<ng-container *ngIf="!value">{{ treeSelect.placeholder || 'empty' }}</ng-container>
		</div>

	</ng-template>
</p-treeSelect>

<p-tree
	#tree
	*ngIf="!treeSelect"
	[value]="hierarchy"
	[virtualScroll]="true"
	[virtualScrollItemSize]="28"
	scrollHeight="flex"
	class="block flex-grow-1 min-w-10rem treenode-auto"
	[indentation]="1"
	[loading]="loading"
	selectionMode="single"
	[selection]="selected"
	(selectionChange)="selectionChange($event)"
>
	<ng-template pTemplate="header">

		<div class="grid form-grid fluid" *ngIf="showFilters">
			<div class="field col-fixed mb-0">
				<label for="filter_fam">{{'families.family_label'|translate}}</label>
				<!-- <div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_fam"
						[(ngModel)]="filters.fam"
						placeholder="{{'families.family_label'|translate}}…"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.fam || !filters.fam" (click)="filters.fam = null"></button>
				</div> -->
				<div class="">
					<p-iconField iconPosition="right">
						<p-inputIcon styleClass="pi pi-times clickable" *ngIf="!!filters.fam" (click)="filters.fam = null"/>
						<input
							pInputText
							type="text"
							name="filter_fam"
							[(ngModel)]="filters.fam"
							(onDebounce)="filterChange()"
							placeholder="{{'families.family_label'|translate}}…"
						/>
					</p-iconField>
				</div>
			</div>

			<div class="field col-fixed mb-0">
				<label for="filter_thm">{{'themes.theme_label'|translate}}</label>
				<!-- <div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_thm"
						[(ngModel)]="filters.thm"
						placeholder="{{'themes.theme_label'|translate}}…"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.thm || !filters.thm" (click)="filters.thm = null"></button>
				</div> -->
				<div class="">
					<p-iconField iconPosition="right">
						<p-inputIcon styleClass="pi pi-times clickable" *ngIf="!!filters.thm" (click)="filters.thm = null"/>
						<input
							pInputText
							type="text"
							name="filter_thm"
							[(ngModel)]="filters.thm"
							(onDebounce)="filterChange()"
							placeholder="{{'themes.theme_label'|translate}}…"
						/>
					</p-iconField>
				</div>
			</div>

			<div class="field col-fixed mb-0">
				<label for="filter_rub">{{'rubriques.rubrique_label'|translate}}</label>
				<!-- <div class="p-inputgroup mr-2">
					<input
						type="text"
						pInputText
						name="filter_rub"
						[(ngModel)]="filters.rub"
						placeholder="{{'rubriques.rubrique_label'|translate}}…"
						(onDebounce)="filterChange()"
					>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!filters.rub || !filters.rub" (click)="filters.rub = null"></button>
				</div> -->
				<div class="">
					<p-iconField iconPosition="right">
						<p-inputIcon styleClass="pi pi-times clickable" *ngIf="!!filters.rub" (click)="filters.rub = null"/>
						<input
							pInputText
							type="text"
							name="filter_rub"
							[(ngModel)]="filters.rub"
							(onDebounce)="filterChange()"
							placeholder="{{'rubriques.rubrique_label'|translate}}…"
						/>
					</p-iconField>
				</div>
			</div>

			<div class="field-checkbox col-fixed align-items-end">
				<p-triStateCheckbox
					name="showInactive"
					label="{{'common.inactive'|translate}}"
					[(ngModel)]="showInactive"
					(onChange)="filterChange()"
					checkboxFalseIcon=""
					[allowNull]="true"
					[trueValue]="false"
					[falseValue]="true"
					[highLightFalse]="false"
					checkboxTrueIcon="pi pi-check"
					checkboxNullIcon="pi pi-minus"
					[highLightNull]="true"
					class="mb-2"
					*ngIf="showInactiveFilter"
				></p-triStateCheckbox>

				<!-- <button
					type="button"
					pButton
					icon="pi pi-refresh"
					class="p-button-rounded p-button-text p-button-sm p-button-danger"
					[loading]="loading"
					(click)="load()"
				></button> -->
			</div>

			<!-- <div class="field col-fixed flex align-items-end">
				<button
					type="button"
					pButton
					class="p-button-text p-button-sm"
					[disabled]="filters.fam == null && filters.thm == null && filters.rub == null && showInactive == false"
					label="{{'common.reset_filters'|translate}}"
					(click)="resetFilter()"
				></button>
			</div> -->
		</div>


		<div *ngIf="showSimpleFilter" class="field ">
			<!-- <input #filter type="search" autocomplete="off" class="p-tree-filter p-inputtext p-component" [placeholder]="filterPlaceholder" (keydown.enter)="$event.preventDefault()" (input)="tree._filter($event.target.value)" /> -->
			<!-- <SearchIcon [styleClass]="'p-tree-filter-icon'" /> -->
			<label for="simpleFilter">{{'nomemclature.label'|translate}}</label>
			<div class="">
				<p-iconField iconPosition="right">
					<p-inputIcon styleClass="pi pi-times clickable" *ngIf="!!simpleFilter" (click)="simpleFilter = ''"/>
					<input
						pInputText
						type="text"
						name="simpleFilter"
						[(ngModel)]="simpleFilter"
						(onDebounce)="tree._filter(simpleFilter)"
						placeholder="{{'common.search_input_placeholder'|translate}}"
					/>
				</p-iconField>
			</div>
		</div>

		<div class="flex flex-row">

			<button
				type="button"
				pButton
				icon="p-button-icon-left pi {{ expanded? 'pi-arrow-down-left-and-arrow-up-right-to-center' : 'pi-arrow-up-right-and-arrow-down-left-from-center' }}"
				class="p-button-rounded p-button-text p-button-sm"
				(click)="toggleExpand()"
			>
				<span [translate]="expanded? 'common.collapse' : 'common.expand' "></span>
			</button>

		</div>
	</ng-template>

	<ng-template let-node pTemplate="default">
		<div class="flex align-items-center nomenclature-item" title="{{node.label}}" id="{{node.key}}">
			<!-- <div
				class="white-space-nowrap text-overflow-ellipsis overflow-hidden flex-grow-1"
				[ngClass]="{'line-through': !node.data.actif}"
				(click)="nodeClick"
			> -->
			<div
				class="bg-green-100"
				[ngClass]="{'line-through': !node.data.actif}"
				(click)="nodeClick"
			>
				<i
					class="pi pi-at text-primary"
					*ngIf="node.typeNomenclature == 'rubrique' && node.data.abo_id_privatisation"
					title="{{'common.private'|translate}}"
				></i>
				{{node.label}}
			</div>
		</div>
	</ng-template>


</p-tree>




