<h4 class="text-center">
	{{ 'auth.password_reset_form_title' | translate }}
</h4>
<p class="mb-4">
	{{ 'auth.password_reset_explanation' | translate }}
</p>
<form class="p-fluid mb-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
	<div class="form-group field">
		<label>
			{{ 'auth.email_address_label' | translate }}
		</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
			<input
				type="email"
				pInputText
				formControlName="email"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': submitted && f.email.errors }"
				placeholder="email@exemple.com"
			>
		</div>
		<div *ngIf="submitted && f.email.errors" class="invalid-feedback p-invalid">
			<small *ngIf="f.email.errors.required">
				{{ 'auth.email_required' | translate }}
			</small>
			<small *ngIf="f.email.errors.email">
				{{ 'auth.invalid_email_address' | translate }}
			</small>
		</div>
	</div>

	<p-message severity="error" text="{{error}}" *ngIf="error && !loading"></p-message>

	<button
		type="submit"
		pButton
		[label]="'auth.reinit_password' | translate"
		[disabled]="!resetPasswordForm.valid || loading"
		*ngIf="!success"
	></button>
	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>

	<p-message severity="success" text="{{ 'auth.login_form_password_reinit_sending_success_summary' | translate}}" *ngIf="success"></p-message>

</form>

<p class="text-center">
	<a uiSref="auth.login">
		{{ 'auth.go_back_to_login_form' | translate }}
	</a>
</p>
