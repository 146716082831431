import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { UIRouter, PathNode, Transition } from '@uirouter/core';
import { TranslateService } from "@codeandweb/ngx-translate";
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { FR_LANG, EN_LANG } from '@global/constantes-langages';

import { PrimeNGConfig } from 'primeng/api';

import { ExtensibleObject, uid } from '@helpers/utils';
import { primengTexts } from '@helpers/primeng-texts';
import { ParametreService } from '@app/parametre/parametre.service';
import { Breadcrumb } from '@global/breadcrumbs.module';
import { StorageService } from '@global/storage.service';

import * as translationsFR from "../assets/i18n/fr.json";
import * as translationsEN from "../assets/i18n/en.json";

@Component({
	selector: 'app-root',
	template: `
		<p-toast [preventOpenDuplicates]="true"></p-toast>
		<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" styleClass="col-12 md:col-6" appendTo="body"></p-confirmDialog>
		<ui-view></ui-view>
		<p-scrollTop></p-scrollTop>
	`,
	providers: [ MessageService ]
})
export class AppComponent implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private stateChangeUnsubscribe: Function;
	private langChangeSubscription: Subscription;
	public crumbs: Breadcrumb[] = [];

	baseTitle: string;

	constructor(
		private eventManager: EventManagerService,
		private messageService: MessageService,
		private parametreService: ParametreService,
		private primeNGConfig: PrimeNGConfig,
		private storageService: StorageService,
		private titleService: Title,
		private translateService: TranslateService,
		private uiRouter: UIRouter,
	) {
		this.eventManager.registerEvent('toast', this, (args: any) => {
				this.toast(args);
			}
		);

		this.setLanguage();

		this.baseTitle = this.parametreService.getParam('instance_name', 'HLina');

		this.updateCrumbs(this.uiRouter.globals.successfulTransitions.peekTail());

		this.stateChangeUnsubscribe = this.uiRouter.transitionService.onSuccess({}, trans => this.updateCrumbs(trans));

		this.langChangeSubscription = translateService.onLangChange.subscribe(() => {
			this.updateTitle();
		});

		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.resetTitle();
		});

	}

	ngOnInit() {

	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('toast', this);
		this.stateChangeUnsubscribe();
		this.eventManager.unregisterEvent('logout', this);
		this.langChangeSubscription.unsubscribe();
	}

	setLanguage() {
		this.translateService.addLangs([FR_LANG, EN_LANG]);
		this.translateService.setTranslation(FR_LANG, translationsFR);
		this.translateService.setTranslation(EN_LANG, translationsEN);
		// const navigatorLanguage = navigator.language.split('-')[0];
		// const storageLanguage = this.storageService.get('lang', navigatorLanguage);
		const storageLanguage = this.storageService.get('lang', FR_LANG);
		const texts: ExtensibleObject = primengTexts;
		this.primeNGConfig.setTranslation(texts[storageLanguage]);
		this.translateService.use(storageLanguage);
	}

	toast(toast: any) {
		if (toast instanceof Array) {
			toast.forEach(one => this.prepareToast(one));
		}
		else {
			this.prepareToast(toast);
		}
		this.messageService.add(toast);
	}

	prepareToast(toast: any) {
		toast.severity = toast.severity || 'info';
		if (toast.severity == 'error') toast.life = 10000;
	}

	private updateCrumbs(trans: Transition) {
		this.crumbs = trans.treeChanges('to')
		.filter(node => node.state.breadcrumb)
		.map(node => {
			let state: string = (typeof node.state.redirectTo == 'string')? node.state.redirectTo : node.state.name;
			return {
				state: state,
				abstract: node.state.abstract,
				label: node.state.breadcrumb?.(trans)
			}
		});

		this.updateTitle();
	}

	private resetTitle() {
		this.titleService.setTitle(this.baseTitle);
	}

	private updateTitle() {
		let crumbs: string[] = this.crumbs
			.filter((one: Breadcrumb) => { return one.label != 'Dashboard' })
			.map((one: Breadcrumb) => { return one.label; })
			.map((one: string) => {
				return this.translateService.instant(one);
			})

		let title = [...crumbs, this.baseTitle].join(' | ');
		this.titleService.setTitle(title);
	}

}
