import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { MenuModule } from '@app/primeng-overrides/menu';
import { PanelModule } from 'primeng/panel';
import { Table, TableModule } from '@app/primeng-overrides/table';
import { ToolbarModule } from 'primeng/toolbar'

import { GlobalModule } from '@global/global.module';
import { StorageService } from '@global/storage.service';
import { FamilleSelectorModule } from '@app/famille/famille-selector';
import { ThemeSelectorModule } from '@app/theme/theme-selector';
import { RubriqueSelectorModule } from '@app/rubrique/rubrique-selector';

import { NomenclatureSelectorModule } from '@app/nomenclature/nomenclature-selector';

import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { Texte } from '../texte/texte.model';

import { environment } from '@environments/environment';

import { simpleDateToString } from '@helpers/utils';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';


@Component({
	selector: 'liste-textes',
	templateUrl: `liste-textes.component.html`
})
export class ListeTextesComponent implements OnInit {

	@ViewChild('tableTextes', { static: true }) table: Table;

	@Input() abonne_courant: Abonne;

	values: Texte[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = environment.default_rows;

	_defaultParams: { [key: string]: any } = {
		search: null,
		search_reference: null,
		fam_id: null,
		thm_id: null,
		rub_id: null,
		orphelin: false,
		date_debut: null,
		date_fin: null
	};

	params = structuredClone(this._defaultParams);

	advancedFilters: boolean = false;

	menuItems: MenuItem[] = [];
	canEdit = false;

	nomenclatureFilter: any;

	today: Date = new Date();

	dateRange: Date[] = [];

	constructor(
		private storageService: StorageService,
		private stateService: StateService,
		private translate: TranslateService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private nomenclatureService: NomenclatureService,
		private utilisateurService: UtilisateurService
	) {

		this.canEdit = !!this.utilisateurService.currentUtilisateurValue?.uti_administrateur;

	}

	private initializeMenu() {
		if (this.canEdit) {
			this.menuItems = [
				{
					label: this.translate.instant('common.create_action_label'),
					icon: 'pi pi-plus',
					command: () => {
						this.stateService.go('.create');
					}
				}
			];
		}
	}

	ngOnInit(): void {
		this.initializeMenu();
		this.getParamSet();

		this.advancedFilters = this.showAdvancedFilters();
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;

			if (tableParams.date_debut && tableParams.date_fin) {
				this.dateRange = [
					new Date(tableParams.date_debut),
					new Date(tableParams.date_fin)
				]
			}
			if (tableParams.nomenclatureFilter) {
				this.nomenclatureFilter = tableParams.nomenclatureFilter;
			}
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows,
				nomenclatureFilter: this.nomenclatureFilter
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);

			const uiParams: any = Object.assign({}, this.stateService.params, this.params, {
				sit_actif: undefined
			});
			this.stateService.go('.', uiParams, { reload: false, location: 'replace', inherit: false });
		}
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	loadTextes(event: LazyLoadEvent) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => {
			this.loading = true;
		});

		const tmpParams = Object.assign({}, event, this.params);

		this.saveParamSet();

		this.nomenclatureService.getTextes(tmpParams)
		.subscribe({
			next: ({ textes, total }) => {
				this.totalRecords = total;
				this.values = textes;

				// si on revient sur la liste paginée et que la page en question est vide, on reset
				if (this.values.length === 0 && this.table.first != 0) {
					this.refresh();
				}
			}
		})
		.add(() => { this.loading = false; });
	}

	showAdvancedFilters() {
		return this.params.orphelin
			|| this.params.date_debut
			|| this.params.date_fin
		;
	}

	toggleAdvancedFilters() {
		this.advancedFilters = !this.advancedFilters;
	}

	setDates(dateRange?: any) {
		this.params.date_debut = null;
		this.params.date_fin = null;
		if (dateRange && dateRange[1]) {
			this.params.date_debut = simpleDateToString(dateRange[0], true);
			this.params.date_fin = simpleDateToString(dateRange[1], true);
		}
		else {
			this.dateRange = [];
		}
		this.refresh();
	}

	resetDates() {
		this.setDates();
	}

	nomenclatureSelectionChange(event: any) {
		this.params.fam_id = null;
		this.params.thm_id = null;
		this.params.rub_id = null;
		if (event) {
			this.params.fam_id = event.fam_id;
			this.params.thm_id = event.thm_id;
			this.params.rub_id = event.rub_id;
		}
		this.refresh();
	}

	resetFilters() {
		this.params = structuredClone(this._defaultParams);
		this.dateRange = [];
		this.refresh();
	}

	deleteTexte(texte: Texte) {
		let message = this.translate.instant('textes.deletion_confirmation', {text: texte.txt_reference});

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.nomenclatureService.deleteTexte(texte.txt_id).subscribe({
					complete: () => {
						this.eventManager.emit('toast', { severity: 'success', summary: this.translate.instant('textes.deletion_success') });
						this.refresh();
					}
				});
			}
		});
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		AccordionModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		IconFieldModule,
		InputIconModule,
		MenuModule,
		PanelModule,
		TableModule,
		ToolbarModule,
		FamilleSelectorModule,
		ThemeSelectorModule,
		RubriqueSelectorModule,
		NomenclatureSelectorModule
	],
	exports: [ListeTextesComponent],
	declarations: [ListeTextesComponent]
})
export class ListeTextesModule { }
