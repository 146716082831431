<h4 class="text-center">
	{{ 'auth.login_form_title' | translate }}
</h4>
<form class="p-fluid mb-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

	<div class="form-group field">
		<label>
			{{ 'auth.email_address_label' | translate }}
		</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
			<input
				pInputText
				type="text"
				formControlName="email"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': submitted && f.email.errors }"
				placeholder="email@exemple.com"
			>
		</div>
		<div *ngIf="submitted && f.email.errors" class="invalid-feedback p-invalid">
			<small *ngIf="f.email.errors.required">
				{{ 'auth.email_required' | translate }}
			</small>
			<small *ngIf="f.email.errors.email">
				{{ 'auth.invalid_email_address' | translate }}
			</small>
		</div>
	</div>
	<div class="form-group field">
		<label>
			{{ 'auth.password_label' | translate }}
		</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input
				type="password"
				pInputText
				formControlName="password"
				[attr.disabled]="loading? true : null"
				class="form-control"
				[ngClass]="{ 'p-invalid': submitted && f.password.errors }"
			/>
		</div>
		<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
			<small *ngIf="f.password.errors.required" class="p-invalid">
				{{ 'auth.password_required' | translate }}
			</small>
		</div>
	</div>
	<div class="form-group field">
		<div class="field-checkbox">
			<p-checkbox
				inputId="remember_me"
				[formControl]="loginForm.controls['remember_me']"
				[binary]="true"
				label="{{'auth.remember_me'|translate}}"
				[disabled]="loading"
			></p-checkbox>
		</div>
	</div>

	<p-message severity="error" text="{{error}}" *ngIf="error && !loading"></p-message>


	<button
		type="submit"
		pButton
		class=""
		label="{{'auth.login'|translate}}"
		[disabled]="loading"
	></button>
	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>
</form>

<p class="text-center">
	<a uiSref="auth.reset_password" [innerHTML]="'auth.link_text_to_password_reset' | translate"></a>
</p>
