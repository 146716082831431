import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { GlobalModule } from '@app/_global/global.module';
import { FamilleSelectorModule } from '@app/famille/famille-selector';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'famille-lien-dialog',
	template: `
		<form class="mb-2" #lienAbonneForm="ngForm" (ngSubmit)="submit()">
			<div class="p-fluid formgrid grid">

				<div class="field col-10">
					<label>
						{{'families.family_label' | translate}}
					</label>
					<famille-selector
						class="field col-fixed"
						name="fam_id"
						[(ngModel)]="fam_id"
						[placeholder]="'families.family_label' | translate"
						[unavailableFamilles]="unavailableFamilies"
						required
					></famille-selector>
				</div>

			</div>

			<!-- Submit -->
			<div class="text-center w-full mt-4">
				<button
					type="submit"
					pButton
					[label]="'common.add_button_label' | translate"
					[disabled]="!lienAbonneForm.form.valid">
				</button>
			</div>
		</form>
	`,
	providers: []
})
export class FamilleLienDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	fam_id?: number;
	unavailableFamilies: number[];

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
	) {
		this.unavailableFamilies = config.data.unavailableFamilies;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {});
	}

	submit(){
		if (this.fam_id != undefined) {
			this.dialogRef.close(this.fam_id);
		}
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		ButtonModule,
		InputTextareaModule,
		FamilleSelectorModule
	],
	exports: [FamilleLienDialogComponent],
	declarations: [FamilleLienDialogComponent]
})
export class AbonneLienModule { }

