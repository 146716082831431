<div class="flex flex-row gap-3 h-full">

	<!-- Arbre de gauche permettant de sélectionner l'arborescence à ajouter -->
	<div class="h-full flex flex-column w-6">

		<p-toolbar styleClass="mb-3" class="flex-shrink-0">
			<ng-template pTemplate="left">

				<div class="grid form-grid fluid">
					<!-- Recherche de famille -->
					<div class="field col-4">
						<label for="filter_fam">Famille</label>
						<div class="p-inputgroup mr-2">
							<input
								type="text"
								pInputText
								name="filter_fam"
								[(ngModel)]="filters.fam"
								placeholder="{{'common.search_input_placeholder'|translate}}"
								(onDebounce)="filterChange()"
							>
							<button
								type="button"
								class="p-inputgroup-addon"
								icon="pi pi-times"
								pButton
								[disabled]="!filters.fam"
								(click)="filters.fam = null">
							</button>
						</div>
					</div>

					<!-- Recherche de thème -->
					<div class="field col-4">
						<label for="filter_thm">Thème</label>
						<div class="p-inputgroup mr-2">
							<input
								type="text"
								pInputText
								name="filter_thm"
								[(ngModel)]="filters.thm"
								placeholder="{{'common.search_input_placeholder'|translate}}"
								(onDebounce)="filterChange()"
							>
							<button
								type="button"
								class="p-inputgroup-addon"
								icon="pi pi-times"
								pButton
								[disabled]="!filters.thm"
								(click)="filters.thm = null">
							</button>
						</div>
					</div>

					<!-- Recherche de rubrique -->
					<div class="field col-4">
						<label for="filter_rub">Rubrique</label>
						<div class="p-inputgroup mr-2">
							<input
								type="text"
								pInputText
								name="filter_rub"
								[(ngModel)]="filters.rub"
								placeholder="{{'common.search_input_placeholder'|translate}}"
								(onDebounce)="filterChange()"
							>
							<button
								type="button"
								class="p-inputgroup-addon"
								icon="pi pi-times"
								pButton
								[disabled]="!filters.rub"
								(click)="filters.rub = null">
							</button>
						</div>
					</div>

					<!-- Checkbox "Rubriques déjà ajoutées" -->
					<div class="field-checkbox col-fixed mb-0">
						<p-checkbox
							name="showAlreadyIncludedRubriques"
							[binary]="true"
							[(ngModel)]="showAlreadyIncludedRubriques"
							(onChange)="filterChange()"
							[label]="'diagnostics.already_included_rubriques_checkbox_label'| translate"
						></p-checkbox>
						<label>
							<i class="pi pi-question-circle"
								[pTooltip]="'diagnostics.tooltip_show_included_rubriques' | translate"
								tooltipStyleClass="max-w-max"
							></i>
						</label>
					</div>

					<!-- Bouton de réinitialisation des filtres -->
					<div class="field col-fixed pl-0 mb-0">
						<button
							type="button"
							pButton
							class="p-button-text p-button-sm"
							[disabled]="filters.fam == null && filters.thm == null && filters.rub == null"
							label="{{'common.reset_filters'|translate}}"
							(click)="resetFilter()"
						></button>
					</div>
				</div>


			</ng-template>
		</p-toolbar>

		<div class="mb-3 text-lg text-center">
			{{'diagnostics.available_rubriques' | translate}}
		</div>

		<p-tree
			[value]="availableHierarchy"
			class="w-full md:w-30rem"
			[virtualScroll]="true"
			[virtualScrollItemSize]="28"
			scrollHeight="flex"
			class="block flex-grow-1"
			[indentation]="1"
			[loading]="loadingAvailableNomenclature"
			[emptyMessage]="
				(filters.fam == null && filters.thm == null && filters.rub == null) ?
				(
					(allRubriquesAlreadyIncluded
						? 'diagnostics.all_rubriques_already_inluded'
						: 'nomenclature.empty_available_nomenclature_message'
					) | translate
				)
				: ('nomenclature.no_element_found' | translate)
			"
		>
			<ng-template pTemplate="header">
				<!-- Réduction de l'arbre -->
				<div>
					<button
						type="button"
						pButton
						icon="p-button-icon-left pi {{ expanded? 'pi-arrow-down-left-and-arrow-up-right-to-center' : 'pi-arrow-up-right-and-arrow-down-left-from-center' }}"
						class="p-button-rounded p-button-text p-button-sm"
						(click)="toggleExpandAvailable()"
					>
						<span [translate]="expandedAvailable? 'common.collapse' : 'common.expand' "></span>
					</button>
				</div>
			</ng-template>

			<ng-template let-node pTemplate="default">
				<div class="flex align-items-center nomenclature-item" title="{{node.label}}">
					<!-- Label du noeud, peut être barré si le noeud est inactif -->
					<div
						class="white-space-nowrap text-overflow-ellipsis overflow-hidden flex-grow-1"
						[ngClass]="{'line-through': !node.data.actif}"
					>
						<i
							class="pi pi-lock text-primary"
							*ngIf="node.typeNomenclature == 'rubrique' && node.data.abo_id_privatisation"
							title="{{'common.private'|translate}}"
						></i>
						{{node.label}}
					</div>
					<div class="white-space-nowrap flex-shrink-0 nomenclature-buttons">
						<!-- Détails -->
						<button
							type="button"
							pButton
							icon="pi pi-eye"
							class="p-button-rounded p-button-text p-button-sm"
							(click)="showItemDialog(node.data)"
						></button>

						<!-- Ajout au diagnostic -->
						<button
							*ngIf="!node.data[PENDING_ADD_MARKER] && !node.data[DEFINITELY_ADDED_MARKER]"
							type="button"
							pButton
							icon="pi pi-arrow-right"
							class="p-button-rounded p-button-text p-button-sm"
							(click)="addRubriquesOfSubTree(node)"
							[title]="
								node.typeNomenclature == 'famille'
									? ('diagnostics.add_all_rubriques_of_familly_to_diagnostic' | translate)
								: node.typeNomenclature == 'theme'
									? ('diagnostics.add_all_rubriques_of_theme_to_diagnostic' | translate)
								: ('diagnostics.add_rubrique_to_diagnostic' | translate)
							"
						></button>

					</div>
				</div>
			</ng-template>
		</p-tree>

	</div>

	<!-- Arbre de droite: arborescence actuelle du diagnostic -->
	<div class="h-full flex flex-column w-6">
		<p-toolbar styleClass="mb-3" class="flex-shrink-0">
			<ng-template pTemplate="left">

				<div class="grid form-grid">
					<!-- Recherche de famille -->
					<div class="field col-4">
						<label for="diagnostic_filter_fam">Famille</label>
						<div class="p-inputgroup mr-2">
							<input
								type="text"
								pInputText
								name="diagnostic_filter_fam"
								[(ngModel)]="diagnosticFilters.fam"
								placeholder="{{'common.search_input_placeholder'|translate}}"
								(onDebounce)="diagnosticFilterChange()"
							>
							<button
								type="button"
								class="p-inputgroup-addon"
								icon="pi pi-times"
								pButton
								[disabled]="!diagnosticFilters.fam"
								(click)="diagnosticFilters.fam = null">
							</button>
						</div>
					</div>

					<!-- Recherche de thème -->
					<div class="field col-4">
						<label for="diagnostic_filter_thm">Thème</label>
						<div class="p-inputgroup mr-2">
							<input
								type="text"
								pInputText
								name="diagnostic_filter_thm"
								[(ngModel)]="diagnosticFilters.thm"
								placeholder="{{'common.search_input_placeholder'|translate}}"
								(onDebounce)="diagnosticFilterChange()"
							>
							<button
								type="button"
								class="p-inputgroup-addon"
								icon="pi pi-times"
								pButton
								[disabled]="!diagnosticFilters.thm"
								(click)="diagnosticFilters.thm = null">
							</button>
						</div>
					</div>

					<!-- Recherche de rubrique -->
					<div class="field col-4">
						<label for="diagnostic_filter_rub">Rubrique</label>
						<div class="p-inputgroup mr-2">
							<input
								type="text"
								pInputText
								name="diagnostic_filter_rub"
								[(ngModel)]="diagnosticFilters.rub"
								placeholder="{{'common.search_input_placeholder'|translate}}"
								(onDebounce)="diagnosticFilterChange()"
							>
							<button
								type="button"
								class="p-inputgroup-addon"
								icon="pi pi-times"
								pButton
								[disabled]="!diagnosticFilters.rub || !diagnosticFilters.rub"
								(click)="diagnosticFilters.rub = null">
							</button>
						</div>
					</div>

					<!-- Coche tri-state inactifs -->
					<div class="field-checkbox col-fixed align-items-end mb-0">
						<p-triStateCheckbox
							name="showDisabledRubriquesDiagnostic"
							label="{{'diagnostics.disabled'|translate}}"
							[(ngModel)]="showDisabledRubriquesDiagnostic"
							(onChange)="diagnosticFilterChange()"
							checkboxFalseIcon=""
							[allowNull]="true"
							[trueValue]="false"
							[falseValue]="true"
							[highLightFalse]="false"
							checkboxTrueIcon="pi pi-check"
							checkboxNullIcon="pi pi-minus"
							[highLightNull]="true"
							class="mb-2"
						></p-triStateCheckbox>
					</div>

					<!-- Bouton de réinitialisation des filtres -->
					<div class="field col-fixed flex align-items-end mb-0">
						<button
							type="button"
							pButton
							class="p-button-text p-button-sm"
							[disabled]="diagnosticFilters.fam == null && diagnosticFilters.thm == null && diagnosticFilters.rub == null && showDisabledRubriquesDiagnostic == true"
							label="{{'common.reset_filters'|translate}}"
							(click)="resetDiagnosticFilter()"
						></button>
					</div>
				</div>
			</ng-template>
		</p-toolbar>

		<div class="mb-3 text-lg text-center">
			{{'diagnostics.rubriques_diagnostic' | translate}}
		</div>

		<p-tree
			[value]="diagnosticHierarchy"
			class="w-full md:w-30rem"
			[virtualScroll]="true"
			[virtualScrollItemSize]="28"
			scrollHeight="flex"
			class="block flex-grow-1"
			[indentation]="1"
			[loading]="loadingDiagnostic"
		>
			<ng-template pTemplate="header">
				<div class="flex flex-row align-items-center">
					<!-- Bouton enregistrer -->
					<button
						type="button"
						pButton
						class="label-md-none p-1 ml-1"
						[label]="'common.save_button_label' | translate"
						icon="pi pi-save"
						(click)="submit()"
						[disabled]="!hasChanges"
					></button>

					<!-- Bouton dupliquer -->
					<button
						type="button"
						pButton
						class="label-md-none p-1 ml-2"
						[label]="'common.duplicate_button_label' | translate"
						icon="pi pi-copy"
						(click)="onDuplicationDialog()"
						[disabled]="hasChanges"
					></button>

					<!-- Bouton exporter -->
					<button
						type="button"
						pButton
						class="label-md-none p-1 ml-2"
						[label]="'common.export_action_label' | translate"
						icon="pi pi-file-export"
						(click)="export()"
						[disabled]="hasChanges"
					></button>

					<!-- Réduction de l'arbre -->
					<button
						type="button"
						pButton
						icon="p-button-icon-left pi {{ expanded? 'pi-arrow-down-left-and-arrow-up-right-to-center' : 'pi-arrow-up-right-and-arrow-down-left-from-center' }}"
						class="p-button-rounded p-button-text p-button-sm"
						(click)="toggleExpandDiagnostic()"
					>
						<span [translate]="expandedDiagnostic? 'common.collapse' : 'common.expand' "></span>
					</button>
				</div>

			</ng-template>

			<ng-template let-node pTemplate="default">
				<div class="flex align-items-center nomenclature-item" title="{{node.label}}">
					<!-- Label du noeud, peut être barré si le noeud est inactif -->
					<div
						class="white-space-nowrap text-overflow-ellipsis overflow-hidden flex-grow-1"
						[ngClass]="{'line-through': !node.data.actif}"
					>
						<i
							class="pi pi-lock text-primary"
							*ngIf="node.typeNomenclature == 'rubrique' && node.data.abo_id_privatisation"
							title="{{'common.private'|translate}}"
						></i>
						{{node.label}}
					</div>
					<div class="white-space-nowrap flex-shrink-0 nomenclature-buttons">
						<!-- Détails -->
						<button
							type="button"
							pButton
							icon="pi pi-eye"
							class="p-button-rounded p-button-text p-button-sm"
							(click)="showItemDialog(node.data)"
						></button>


						<ng-container *ngIf="canEdit">
							<!-- Désactivation -->
							<button
								*ngIf="
									node.typeNomenclature != 'famille'
									&& !node.data[PENDING_ADD_MARKER]
									&& (node.typeNomenclature != 'rubrique' || node.data.rdi_actif)
								"
								type="button"
								pButton
								icon="pi pi-ban"
								class="p-button-rounded p-button-text p-button-sm p-button-danger"
								title="{{
									node.data.typeNomenclature == 'theme' ? ('diagnostics.disable_theme_rubriques' | translate)
									: ('common.disable' | translate)
								}}"
								(click)="showDisableConfirmationDialog(node.data)"
							></button>

							<!-- Réactivation -->
							<button
								*ngIf="
									!node.data[PENDING_ADD_MARKER]
									&& (node.typeNomenclature != 'rubrique' || !node.data.rdi_actif)
								"
								type="button"
								pButton
								icon="pi pi-check"
								class="p-button-rounded p-button-text p-button-sm"
								title="{{
									node.data.typeNomenclature == 'famille' ? ('diagnostics.reenable_rubriques_of_famille' | translate)
									: node.data.typeNomenclature == 'theme' ? ('diagnostics.reenable_rubriques_of_theme' | translate)
									: ('diagnostics.reenable_rubrique' | translate)
								}}"
								(click)="reEnableSubtree(node.data)"
							></button>

							<!-- Annulation d'ajout -->
							<button
								*ngIf="node.data[PENDING_ADD_MARKER]"
								type="button"
								pButton
								icon="pi pi-undo"
								class="p-button-rounded p-button-text p-button-sm p-button-danger"
								title="{{
									node.data.typeNomenclature == 'famille' ? ('diagnostics.cancel_add_of_famille_rubriques' | translate)
									: node.data.typeNomenclature == 'theme' ? ('diagnostics.cancel_add_of_theme_rubriques' | translate)
									: ('diagnostics.cancel_add' | translate)
								}}"
								(click)="remove(node.data)"
							></button>

						</ng-container>
					</div>
				</div>
			</ng-template>
		</p-tree>

	</div>

</div>
