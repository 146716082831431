import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { AbonneService } from '@app/abonne/abonne.service';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';
import { AbonneLogoDisplayModule } from '../abonne-logo-display/abonne-logo-display';
import { Subscription } from 'rxjs';


@Component({
	selector: 'abonne-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AbonneSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="abonnes"
			[(ngModel)]="value"
			[filter]="abonnes.length > 5"
			filterBy="abo_nom_abonne"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			optionValue="abo_id"
			[showClear]="nullOption"
			appendTo="body"
		>
			<ng-template let-item pTemplate="item">
				<div class="flex flex-row align-items-center">
					<div *ngIf="item.abo_fichier_logo" class="mr-2">
						<logo-display class="logo-listing" [abonne]="item"></logo-display>
					</div>

					<div>
						{{item.abo_nom_abonne}}
					</div>
				</div>
			</ng-template>

			<ng-template let-selectedItem pTemplate="selectedItem">
				<div class="flex flex-row align-items-center">
					<div *ngIf="selectedItem.abo_fichier_logo" class="mr-2">
						<logo-display class="logo-listing" [abonne]="selectedItem"></logo-display>
					</div>

					<div>
						{{selectedItem.abo_nom_abonne}}
					</div>
				</div>
			</ng-template>
		</p-dropdown>
	`
})
export class AbonneSelectorComponent extends ControlValueAccessorBase<number> implements OnInit, OnDestroy  {

	@Input() name: string;
	@Input('value') innerValue: number;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;
	@Input() noAbonneId?: number;
	@Input() unavailableAbonnes?: number[];

	_placeholder: string = 'Sélectionnez';

	abonnes: {abo_id: number, abo_nom_abonne: string}[] = [];

	subscriptionLangChange: Subscription;

	constructor(private abonneService: AbonneService, private translateService: TranslateService) {
		super();

		this.subscriptionLangChange = this.translateService.onLangChange.subscribe(() => {
			this._placeholder = this.translateService.instant('common.selector_placeholder');
		});
	}

	ngOnInit() {
		if (this.placeholder != undefined) {
			this._placeholder = this.placeholder;
		}

		this.abonneService.getAbonnes({})
		.subscribe(({ abonnes }) => {
			this.abonnes = abonnes;

			if (this.unavailableAbonnes != undefined) {
				this.abonnes = this.abonnes.filter(abo => !this.unavailableAbonnes?.includes(abo.abo_id))
			}

			if (this.noAbonneId !== undefined) {
				this.abonnes.unshift({abo_id: this.noAbonneId, abo_nom_abonne: 'Sans abonné'})
			}
		});
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange.unsubscribe();
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule,
		AbonneLogoDisplayModule
	],
	exports: [AbonneSelectorComponent],
	declarations: [AbonneSelectorComponent]
})
export class AbonneSelectorModule { }
