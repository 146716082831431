import { StateService, Transition } from '@uirouter/core';

import { map } from 'rxjs';

import { MenuItem } from 'primeng/api';

import { Abonne } from '@app/abonne/abonne.model';
import { abonneMenuItems } from '@app/abonne-menu-items';
import { AbonneService } from '@app/abonne/abonne.service';
import { AppMenuService } from '@global/app-menu.service';
import { ExtensibleObject, isEmpty } from '@helpers/utils';
import { gestionnaireAbonneMenuItems } from '@app/gestionnaire-abonnement-menu-items';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { SiteService } from '@app/site/site.service';
import { StateUtilsService } from '@app/_helpers/state-utils.service';
import { StorageService } from './storage.service';
import { superAdminMenuItems } from '@app/super-admin-menu-items';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

// empty the sidebar menu
export const resetMenuResolve = {
	token: 'resetMenu',
	deps: [AppMenuService, Transition],
	resolveFn: (appMenuService: AppMenuService, transition: Transition) => {
		// console.log('resetMenuResolve');
		appMenuService.setMenuItems([]);
		return true;
	},
};

export const getMeResolve = {
	token: 'me',
	deps: [StateService, UtilisateurService],
	resolveFn: (stateService: StateService, utilisateurService: UtilisateurService) => {
		// console.log('getMeResolve');
		return utilisateurService.getCurrentUtilisateur().toPromise()
			.then(
				(me: any) => {
					return me;
				},
				(error: any) => {
					if (error.status && error.status != 401) {
						stateService.go('service_unavailable', { error: 'true' });
					}
				},
			);
	},
};

export const getAbonneCourantResolve = {
	token: 'abonne_courant',
	deps: [AbonneService, Transition],
	resolveFn: (abonneService: AbonneService, transition: Transition) => {
		const curr_abo_id = transition.params().curr_abo_id;

		return abonneService.getAbonne(curr_abo_id)
		.pipe(map((abonne: Abonne) => {
			abonneService.currentAbonneValue = abonne;
			return abonne;
		})).toPromise();
	}
};

export const redirectAbonne = {
	token: 'redirectAbonne',
	deps: [StateService, StorageService, AbonneService, Transition, 'me'],
	resolveFn: (
		stateService: StateService,
		storageService: StorageService,
		abonneService: AbonneService,
		transition: Transition,
		me: Utilisateur
	) => {

		// cas particulier de l'admin, l'abonné "courant" (s'il était sélectionné) doit être chargé plus tôt, hors du "main"
		if (me.uti_administrateur) {
			const stored_abo = storageService.get('abo', null);
			if (stored_abo) {
				abonneService.currentAbonneValue = stored_abo;
				return abonneService.refreshCurrentAbonneData().toPromise();
			}
		}

		// si utilisateur "normal", on gère juste le cas du state "main"
		// si l'utilisateur n'a qu'un abonnement, on le redirige immédiatement sur celui-ci
		// le getAbonneCourantResolve se chargera du reste
		if (transition.to().name == 'main' && me.abonnes.length == 1) {
			return stateService.go('abonne-courant', {curr_abo_id: me.abonnes[0].abo_id});
		}

		return true;
	}
}

export const getUtilisateurResolve = {
	token: 'utilisateur',
	deps: [UtilisateurService, Transition],
	resolveFn: (utilisateurService: UtilisateurService, transition: Transition) => {
		const uti_id = transition.params().uti_id;
		return utilisateurService.getUtilisateur(uti_id).toPromise();
	}
};

export const getSiteResolve = {
	token: 'site',
	deps: [SiteService, Transition, 'curr_abo_id'],
	resolveFn: (siteService: SiteService, transition: Transition, curr_abo_id: number) => {
		const sit_id = transition.params().sit_id;
		return siteService.getSite(curr_abo_id, sit_id).toPromise();
	}
};

export const getTexteResolve = {
	token: 'texte',
	deps: [NomenclatureService, Transition],
	resolveFn: (texteService: NomenclatureService, transition: Transition) => {
		const txt_id = transition.params().txt_id;
		return texteService.getTexte(txt_id).toPromise();
	}
};

export const registerMenuItems = {
	token: 'registerMenuItems',
	deps: [AppMenuService, AbonneService, Transition, 'me'],
	resolveFn: (appMenuService: AppMenuService, abonneService: AbonneService, transition: Transition, me: Utilisateur) => {

		abonneService.currentAbonne$.pipe(map((abonne: Abonne | null) => {
			if (abonne == null) {
				if (me.uti_administrateur) {
					let menuItems: MenuItem[] = [
						{
							labelKey: 'menu.administration',
							icon: 'pi pi-cog',
							items: superAdminMenuItems
						}
					];
					appMenuService.setMenuItems(menuItems);
				}
				else {
					appMenuService.setMenuItems([]);
				}

				return;
			}

			let menuItems: MenuItem[] = [];
			let utilisateurAbonne = abonne.utilisateurs_abonnes.find(utilisateur => utilisateur.uti_id == me.uti_id);
			let _gestionnaireAbonneMenuItems = withUiParamsInMenuItems(gestionnaireAbonneMenuItems, {curr_abo_id: abonne.abo_id});
			let _abonneMenuItems = withUiParamsInMenuItems(abonneMenuItems, {curr_abo_id: abonne.abo_id});

			if (me.uti_administrateur) {
				menuItems.push(
					{
						labelKey: 'menu.administration',
						icon: 'pi pi-cog',
						items: superAdminMenuItems
					},
					{
						labelKey: 'menu.abonne',
						icon: 'pi pi-building',
						items: _gestionnaireAbonneMenuItems
					}
				);
			}
			else if (utilisateurAbonne != undefined && utilisateurAbonne.utb_gestion_abonnement) {
				menuItems.push(
					{
						labelKey: 'menu.abonne',
						icon: 'pi pi-building',
						items: _gestionnaireAbonneMenuItems
					});
			} else {
				menuItems.push({
					labelKey: 'menu.abonne',
					icon: 'pi pi-building',
					items: _abonneMenuItems
				});
			}
			appMenuService.setMenuItems(menuItems);
		})).subscribe();
	},
};

// function to determine where the user should be redirected
// after having been redirected to the login form
export const returnToResolve = {
	token: 'returnTo',
	deps: [StateService, StateUtilsService, Transition],
	resolveFn: (stateService: StateService, stateUtils: StateUtilsService, transition: Transition) => {
		// console.log('returnToResolve', transition.to().name, transition.from().name, transition.redirectedFrom());

		// The user was redirected to the login state (e.g., via the requiresAuth hook when trying to activate a state)
		if (transition.redirectedFrom() != null) {
			// Return to the original attempted target state (e.g., state.name)
			return transition.redirectedFrom().targetState();
		}

		// The user was not redirected to the login state; they directly activated the login state somehow.
		// Return them to the state they came from.
		if (transition.from().name !== '' && isEmpty(transition.options().custom)) {
			return stateService.target(transition.from(), transition.params('from'));
		}

		// If the fromState's name is empty, then this was the initial transition. We'll try to return to the targeted url
		return stateUtils.matchUrlToState(window.location.pathname);
	},
};


function withUiParamsInMenuItems(items: any[], params: ExtensibleObject) {
	items = structuredClone(items);
	for (let item of items) {
		if (item.state.startsWith('abonne-courant')) {
			item.uiParams = {curr_abo_id: params.curr_abo_id};
		}
	}

	return items;
}

export const getNomenclatureResolve = {
	token: 'nomenclature',
	deps: [NomenclatureService],
	resolveFn: (nomenclatureService: NomenclatureService) => {
		return nomenclatureService.getCacheNomenclature().toPromise();
	}
};
