import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { Subscription } from 'rxjs';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { NomenclatureService } from '@app/nomenclature/nomenclature.service';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';
import { StatutTexteInfo } from '../texte.model';

@Component({
	selector: 'statut-recolement-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => StatutRecolementSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[filter]="options.length > 5"
			filterBy="rub_nom"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			[virtualScroll]="true"
			[virtualScrollItemSize]="virtualScrollItemSize"
			optionValue="sta_code"
			optionLabel="sta_libelle"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class StatutRecolementSelectorComponent extends ControlValueAccessorBase<number> implements OnInit, OnDestroy  {

	@Input() name: string;
	@Input('value') innerValue: number;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;
	@Input() unavailableRubriques?: number[];
	@Input() fam_id: number;
	@Input() thm_id: number;

	_placeholder: string = 'Sélectionnez';

	options: StatutTexteInfo[] = [];

	subscriptionLangChange?: Subscription;

	// TODO on devrait centraliser ça
	virtualScrollItemSize: number = 18;

	constructor(private nomenclatureService: NomenclatureService, private translateService: TranslateService) {
		super();

		if (this.placeholder == undefined) {
			this.subscriptionLangChange = this.translateService.onLangChange.subscribe(() => {
				this._placeholder = translateService.instant('common.selector_placeholder');
			});
		}
		nomenclatureService.getCacheStatutsTextes()
		.subscribe((statuts: StatutTexteInfo[]) => {
			this.options = statuts;
		});
	}

	ngOnInit() {
		if (this.placeholder != undefined) {
			this._placeholder = this.placeholder;
		}
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange?.unsubscribe();
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule
	],
	exports: [StatutRecolementSelectorComponent],
	declarations: [StatutRecolementSelectorComponent]
})
export class StatutRecolementSelectorModule { }
