import { NgModule } from '@angular/core';
import { Transition } from '@uirouter/core';

// Add `breadcrumb` to typescript definitions for StateObject and StateDeclaration
declare module "@uirouter/core/lib/state/stateObject" {
	interface StateObject {
		breadcrumb?: (trans: Transition) => string;
	}
}

declare module "@uirouter/core/lib/state/interface" {
	interface StateDeclaration {
		breadcrumb?: (trans: Transition) => string;
	}
}

export interface Breadcrumb {
	state: string;
	label: string|undefined;
}


@NgModule({
	imports: [],
	providers: [],
	declarations: [],
	exports: []
})
export class BreadcrumbModule { }
