<p-panel class="no-header-panel">

	<div class="" *ngIf="!selfEditing && isAdminView">
		<div class="mb-2">
			<button
				*ngIf="!editMode"
				type="button"
				pButton
				[label]="'users.link_to_abonne_button_label' | translate"
				icon="pi pi-link"
				(click)="openAbonneLinkDialog()"
			></button>
		</div>

		<div class="grid">

			<p-card class="col-12 xl:col-6 max-w-40rem" *ngFor="let abonne of utilisateur.abonnes">
				<ng-template pTemplate="header">
					<!-- Header: nom de l'abonné et actions sur l'abonné-->
					<div class="flex justify-content-between p-3">
						<div class="flex align-items-center" title="{{abonne.abo_nom_abonne}}">
							<div class="logo-inline mr-2">
								<logo-display [abonne]="abonne" width="3rem"></logo-display>
							</div>
							<div class="white-space-nowrap overflow-hidden text-overflow-ellipsis">{{abonne.abo_nom_abonne}}</div>
						</div>

						<div class="flex align-items-center flex-shrink-0">
							<a uiSref="admin.abonnes.single" [uiParams]="{abo_id: abonne.abo_id}">
								<button
									type="button"
									pButton
									icon="pi pi-eye"
									title="{{'abonnes.go_to_subscription'|translate}}"
								></button>
							</a>
							<button
								type="button"
								pButton
								icon="pi pi-times-circle"
								class="p-button-rounded p-button-text p-button-danger"
								(click)="unlinkAbonne(abonne)"
								title="{{'abonnes.unlink_subscription'|translate}}"
							></button>
						</div>
					</div>

				</ng-template>

				<!-- Droits: Niveau d'accès et gestion de l'abonnement -->
				<div class="p-fluid formgrid grid">

					<div class="field col-12 md:col-7">
						<label>{{ 'abonnes.access_level_label' | translate }}</label>
						<niveau-access-selector
							name="nac_id"
							[(ngModel)]="abonne.nac_id"
							(ngModelChange)="editUtilisateurAbonne(abonne.abo_id, abonne)"
							[placeholder]="'abonnes.access_level_label' | translate"
							[readonly]="!canEditAccessLevelForAbonne(abonne)"
						>
						</niveau-access-selector>
					</div>


					<div class="col field field-checkbox">
						<p-checkbox
							name="utb_gestion_abonnement"
							inputId="utb_gestion_abonnement"
							[(ngModel)]="abonne.utb_gestion_abonnement"
							(ngModelChange)="editUtilisateurAbonne(abonne.abo_id, abonne)"
							[readonly]="!canEditGestionAbonnementForAbonne(abonne)"
							[binary]="true"
							[label]="'abonnes.subscription_management_right_label' | translate"
						></p-checkbox>
					</div>
				</div>
			</p-card>

		</div>

	</div>

</p-panel>
