import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Rubrique } from '@app/nomenclature/nomenclature.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'nomenclature'})
export class NomenclaturePipe implements PipeTransform {

	constructor() {}

	transform(rubrique: Rubrique, separator: string = ' > '): string {
		let tmp = '';
		if (typeof rubrique.fam_nom != 'undefined') {
			tmp = this.append(tmp, rubrique.fam_nom, separator);
		}
		if (typeof rubrique.thm_nom != 'undefined') {
			tmp = this.append(tmp, rubrique.thm_nom, separator);
		}
		if (typeof rubrique.rub_nom != 'undefined') {
			tmp = this.append(tmp, rubrique.rub_nom, separator);
		}
		return tmp;
	}

	append(str: string, more: string, separator: string = ' ') {
		if (str.length) {
			str = `${str}${separator}`;
		}
		return `${str}${more}`;
	}

}
