import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { MenuModule } from '@app/primeng-overrides/menu';
import { TriStateCheckboxModule } from '@app/primeng-overrides/tristatecheckbox';
import { GlobalModule } from '@global/global.module';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';

import { TranslateModule, TranslateService } from '@codeandweb/ngx-translate';

import { FamilleDialogComponent } from '@app/famille/famille-dialog';
import {
	expandTreeBasedOnFilters,
	getFilteredNomenclature,
	makeNomenclatureTreeNodes,
	Nomenclature,
	NomenclatureFilters,
	NomenclatureNodeData,
	NomenclatureService,
	walkTree
} from '@app/nomenclature/nomenclature.service';
import { RubriqueDialogComponent } from '@app/rubrique/rubrique-dialog';
import { ThemeDialogComponent } from '@app/theme/theme-dialog';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { EventManagerService } from '@global/event-manager.service';

import { ExtensibleObject, expandTreeData } from '@app/_helpers/utils';


@Component({
	selector: 'nomenclature',
	templateUrl: `./nomenclature.html`
})
export class NomenclatureComponent implements OnInit {

	unfilteredNomenclature: Nomenclature = {familles: [], rubriques: [], themes: []};
	hierarchy: TreeNode<NomenclatureNodeData>[];

	canEdit: boolean = true;
	loading: boolean = false;
	loadingItem: string|null = null;

	_defaultFilter: any = {
		fam: null,
		thm: null,
		rub: null,
	}

	showInactive: boolean | null = false;
	filters: NomenclatureFilters = structuredClone(this._defaultFilter);
	expanded: boolean = false;

	constructor(
		private confirmationService: ConfirmationService,
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private translate: TranslateService,
		private nomenclatureService: NomenclatureService
	) {

	}

	ngOnInit() {
		this.load(true);
	}

	load(initial = false) {
		this.loading = true;
		this.nomenclatureService.getCacheNomenclature()
		.subscribe({
			next: (response: Nomenclature) => {
				this.unfilteredNomenclature = response;
				this.updateTree(getFilteredNomenclature(this.unfilteredNomenclature, this.filters));
				if (initial) {
					this.toggleExpand(this.expanded);
				}
			}
		})
		.add(() => { this.loading = false; });
	}

	updateTree(nomenclature: Nomenclature) {
		let expandedNodeKeys = new Set<string>();
		if (this.hierarchy != undefined) {
			walkTree(this.hierarchy, (node: TreeNode) => {
				if (node.key && node.expanded) {
					expandedNodeKeys.add(node.key);
				}
			});
		}
		this.hierarchy = structuredClone(makeNomenclatureTreeNodes(nomenclature, this.showInactive, expandedNodeKeys));
	}

	resetFilter() {
		this.showInactive = false;
		this.filters = structuredClone(this._defaultFilter);
		this.filterChange();
	}

	filterChange() {
		this.updateTree(getFilteredNomenclature(this.unfilteredNomenclature, this.filters));
		expandTreeBasedOnFilters(this.hierarchy, this.filters);
	}

	toggleExpand(expand: boolean = !this.expanded) {
		this.hierarchy = expandTreeData(this.hierarchy, expand);
		this.expanded = expand;
	}

	showItemDialog(item: NomenclatureNodeData) {
		let dialogTitle: string;
		let dialogComponent: any;
		let data: ExtensibleObject = {};

		switch (item.typeNomenclature) {
		case 'famille':
			dialogTitle = item.fam_nom;
			dialogComponent = FamilleDialogComponent;
			data = {
				famille: item,
				canEdit: this.canEdit
			};
			break;
		case 'theme':
			dialogTitle = item.thm_nom;
			dialogComponent = ThemeDialogComponent;
			data = {
				famille: item,
				theme: item,
				canEdit: this.canEdit
			};
			break;
		default: // rubrique
			dialogTitle = item.rub_nom;
			dialogComponent = RubriqueDialogComponent;
			data = {
				famille: item,
				theme: item,
				rubrique: item,
				canEdit: this.canEdit
			};
		}

		const ref = this.dialogService.open(dialogComponent, {
			data: data,
			header: dialogTitle,
			width: '70rem'
		});

		ref.onClose.subscribe(dialogResult => {
			if (dialogResult) {
				this.load();
			}
		});
	}

	showChildItemCreationDialog(parent?: NomenclatureNodeData) {
		let dialogTitle: string;
		let dialogComponent: any;
		let data: ExtensibleObject = {};


		if (parent == undefined) { // création de famille
			dialogTitle = this.translate.instant('nomenclature.new_famille');
			dialogComponent = FamilleDialogComponent;
			data = {
				canEdit: true
			};
		} else if (parent.typeNomenclature == 'famille') {
			dialogTitle = this.translate.instant('nomenclature.new_theme');
			dialogComponent = ThemeDialogComponent;
			data = {
				famille: parent,
				canEdit: true
			};
		}
		else { // thème : création de rubrique
			dialogTitle = this.translate.instant('nomenclature.new_rubrique');
			dialogComponent = RubriqueDialogComponent;
			data = {
				theme: parent,
				canEdit: true
			};
		}

		const ref = this.dialogService.open(dialogComponent, {
			data: data,
			header: dialogTitle,
			width: '70rem'
		});

		ref.onClose.subscribe(dialogResult => {
			if (dialogResult) {
				this.load();
			}
		});
	}

	deleteItem(item: NomenclatureNodeData) {
		let message: string;
		let successMessage: string;
		let appCall: Observable<any>;

		switch (item.typeNomenclature) {
		case 'famille':
			message = this.translate.instant('families.deletion_confirmation', {name: item.fam_nom});
			successMessage = this.translate.instant('families.deletion_success');
			appCall = this.nomenclatureService.deleteFamille(item.fam_id);
			break;
		case 'theme':
			message = this.translate.instant('themes.deletion_confirmation', {name: item.thm_nom});
			successMessage = this.translate.instant('themes.deletion_success');
			appCall = this.nomenclatureService.deleteTheme(item.fam_id, item.thm_id);
			break;
		default: // rubrique
			message = this.translate.instant('rubriques.deletion_confirmation', {name: item.rub_nom});
			successMessage = this.translate.instant('rubriques.deletion_success');
			appCall = this.nomenclatureService.deleteRubrique(item.fam_id, item.thm_id, item.rub_id);
			break;
		}

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.loadingItem = item.key;
				appCall.subscribe({
					complete: () => {
						this.eventManager.emit('toast', {severity: 'success', summary: successMessage});
						this.load();
					}
				})
				.add(() => {this.loadingItem = null;})
			}
		});
	}

}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		InputTextModule,
		MenuModule,
		ToolbarModule,
		TreeModule,
		TriStateCheckboxModule,
		TranslateModule,
	],
	exports: [NomenclatureComponent],
	declarations: [NomenclatureComponent]
})
export class NomenclatureModule { }


