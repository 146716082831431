import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';

import { UIRouterModule } from '@uirouter/angular';

import { AppMenuModule } from '@app/_global/app-menu/app-menu';

import { GlobalModule } from '@global/global.module';
import { AppBreadcrumbsModule } from '@global/app-breadcrumbs';


@Component({
	selector: 'main-content',
	template: `
		<app-menu></app-menu>
		<div class="main-content">
			<app-breadcrumbs class="mb-2 flex-shrink-0"></app-breadcrumbs>
			<ui-view name="mainContent"></ui-view>
		</div>
	`
})
export class MainContentComponent implements OnInit {


	constructor() {

	}

	ngOnInit(): void {

	}
}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		GlobalModule,
		AppMenuModule,
		AppBreadcrumbsModule
	],
	exports: [MainContentComponent],
	declarations: [MainContentComponent]
})
export class MainContentModule { }
